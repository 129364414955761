import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import ArrowIcon from '../icons/ArrowIcon';
import FloorIcon from '../icons/FloorIcon';
/** Increment all 0+ levels if countFromFirstFloor is set to true  */
const incrementLevel = (levelKey, floorIndexing) => floorIndexing === 1 && parseFloat(levelKey) >= 0 ? `${parseFloat(levelKey) + 1}` : levelKey;
const ToggleButton = () => {
    const { gatedTourService, navigationService, tourConfigService, layoutService } = useServices();
    const { levelKey, levelBoundary, transitionInProgress } = useReactive(navigationService, [
        'levelKey',
        'levelBoundary',
        'transitionInProgress',
    ]);
    const { floorIndexing } = useReactive(tourConfigService, ['floorIndexing']);
    const { changeLevel, panoMatchLevel } = navigationService;
    const { activeScene } = useReactive(navigationService, ['activeScene']);
    const { isRemoteControlled } = useReactive(layoutService, ['isRemoteControlled']);
    const [recentlyClicked, setRecentlyClicked] = useState(false);
    const { analytics } = useGlobalContext();
    const twLevelUpHidden = levelBoundary === 'min' || levelBoundary === 'both' ? 'invisible' : '';
    const twLevelDownHidden = levelBoundary === 'max' || levelBoundary === 'both' ? 'invisible' : '';
    const clearRecentlyClicked = useMemo(() => debounce(() => {
        setRecentlyClicked(false);
    }, 900), []);
    useEffect(() => {
        if (recentlyClicked) {
            clearRecentlyClicked();
        }
        return () => {
            clearRecentlyClicked.cancel();
        };
    }, [recentlyClicked, clearRecentlyClicked]);
    const disabled = isRemoteControlled || transitionInProgress;
    return (_jsxs("div", Object.assign({ className: `inline-flex h-10 w-[9.5rem] min-w-[9.5rem] items-center justify-center rounded bg-theme-primary
        bg-opacity-theme-low fill-theme-primary stroke-theme-primary p-1.5 font-primary text-base font-bold
        text-theme-primary` }, { children: [_jsx("button", Object.assign({ disabled: disabled, type: "button", className: `btn-theme-primary h-7 w-7 rounded-[0.313rem] p-0 disabled:cursor-not-allowed
          disabled:hover:bg-opacity-theme-low ${twLevelUpHidden}`, "data-testid": "floor-down-btn", onClick: () => {
                    if (disabled)
                        return;
                    analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FSW', 'Down', {
                        scene_id: activeScene.sceneKey,
                        floor: `${parseFloat(levelKey) - 1}`,
                    });
                    if (!gatedTourService.checkVerification())
                        return;
                    if (!panoMatchLevel(-1))
                        return;
                    setRecentlyClicked(true);
                    changeLevel(-1);
                } }, { children: _jsx(ArrowIcon, { className: "h-6 w-6", direction: "down", filterSize: 32, shadowIntensity: "dark" }) })), _jsxs("span", Object.assign({ className: "flex grow items-end justify-center text-lg text-shadow-dark" }, { children: [_jsx("span", Object.assign({ className: "min-w-[0.75rem] pb-[1px] text-center", "data-testid": "current-floor" }, { children: incrementLevel(levelKey, floorIndexing) })), _jsx(FloorIcon, { className: "h-8 w-8" })] })), _jsx("button", Object.assign({ disabled: isRemoteControlled, type: "button", className: `btn-theme-primary h-7 w-7 rounded-[0.313rem] p-0 disabled:cursor-not-allowed
          disabled:hover:bg-opacity-theme-low ${twLevelDownHidden}`, "data-testid": "floor-up-btn", onClick: () => {
                    if (disabled)
                        return;
                    analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FSW', 'Up', {
                        scene_id: activeScene.sceneKey,
                        floor: `${parseFloat(levelKey) + 1}`,
                    });
                    if (!gatedTourService.checkVerification())
                        return;
                    if (!panoMatchLevel(1))
                        return;
                    setRecentlyClicked(true);
                    changeLevel(1);
                } }, { children: _jsx(ArrowIcon, { className: "h-6 w-6", direction: "up", filterSize: 32, shadowIntensity: "dark" }) }))] })));
};
export default ToggleButton;
