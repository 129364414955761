import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import CheckBox from '../../common/CheckBox';
import Modal from '../../common/Modal';
import TextButton from '../../common/TextButton';
import { useCookieConsentContext } from '../../contexts/useCookieConsentContext';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useResizeObserver from '../../hooks/useResizeObserver';
import Disclaimer from './components/Disclaimer';
function CookieModal() {
    var _a, _b;
    const scrollRef = useRef(null);
    const [hasOverflowY, setHasOverflowY] = useState(false);
    const { initialPreferences, handleSubmitPreferences, showCookieModal, setShowCookieModal } = useCookieConsentContext();
    const [isPerformance, setIsPerformance] = useState((_a = initialPreferences === null || initialPreferences === void 0 ? void 0 : initialPreferences.isPerformance) !== null && _a !== void 0 ? _a : false);
    const [isPreferences, setIsPreferences] = useState((_b = initialPreferences === null || initialPreferences === void 0 ? void 0 : initialPreferences.isPreferences) !== null && _b !== void 0 ? _b : false);
    const t = useTranslation();
    useEffect(() => {
        var _a, _b;
        setIsPerformance((_a = initialPreferences === null || initialPreferences === void 0 ? void 0 : initialPreferences.isPerformance) !== null && _a !== void 0 ? _a : false);
        setIsPreferences((_b = initialPreferences === null || initialPreferences === void 0 ? void 0 : initialPreferences.isPreferences) !== null && _b !== void 0 ? _b : false);
    }, [initialPreferences]);
    /** Handles the accept all cookies. Sets necessary, performance and preference cookies to true */
    const handleAcceptAll = () => {
        setIsPerformance(true);
        setIsPreferences(true);
        handleSubmitPreferences({ isNecessary: true, isPerformance: true, isPreferences: true });
        setShowCookieModal(false);
    };
    /** Confirms the selection. Necessary is always true, performance and preference are taken from state */
    const handleConfirmSelection = () => {
        handleSubmitPreferences({ isNecessary: true, isPerformance, isPreferences });
        setShowCookieModal(false);
    };
    const handleResize = () => {
        var _a, _b;
        const osInstance = (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.osInstance();
        setHasOverflowY((_b = osInstance === null || osInstance === void 0 ? void 0 : osInstance.state().hasOverflow.y) !== null && _b !== void 0 ? _b : false);
    };
    useResizeObserver(handleResize, scrollRef, true, 5);
    const twContentTopPadding = hasOverflowY ? 'pt-6' : 'pt-2';
    return (_jsx(Modal, Object.assign({ className: "z-cookie-modal", show: showCookieModal, title: t('cookie-consent.cookie-header'), ref: scrollRef }, { children: _jsxs("div", Object.assign({ className: `px-8 pb-6 ${twContentTopPadding}` }, { children: [_jsx(Disclaimer, { className: "mb-8 font-secondary text-base font-normal text-modal-gray-dark" }), _jsx(CheckBox, { checked: true, label: t('cookie-consent.necessary-label'), description: t('cookie-consent.necessary-description'), disabled: true, testId: "necessary-cookie-checkbox" }), _jsx(CheckBox, { checked: isPerformance, label: t('cookie-consent.performance-label'), description: t('cookie-consent.performance-description'), onChange: () => setIsPerformance(!isPerformance), testId: "performance-cookie-checkbox" }), _jsx(CheckBox, { checked: isPreferences, label: t('cookie-consent.preferences-label'), description: t('cookie-consent.preferences-description'), onChange: () => setIsPreferences(!isPreferences), testId: "preferences-cookie-checkbox" }), _jsxs("div", Object.assign({ className: "mt-8 flex flex-wrap-reverse justify-end gap-4" }, { children: [_jsx(TextButton, Object.assign({ className: "h-10 w-full @xl:w-auto", variant: "secondary", onClick: handleConfirmSelection, testId: "confirm-selection-btn" }, { children: t('cookie-consent.button-confirm') })), _jsx(TextButton, Object.assign({ className: "h-10 w-full @xl:w-auto", variant: "primary", onClick: handleAcceptAll, testId: "accept-all-btn" }, { children: t('cookie-consent.button-all') }))] }))] })) })));
}
export default CookieModal;
