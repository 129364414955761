import type { Subscription } from '@g360/vt-types';

import Utils from '../../common/Utils';
import {
  createTexture,
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { Image, ProgramName } from '../../types/internal';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './equirect.fs.glsl';
import vertexShaderSource from './equirect.vs.glsl';

// TODO(uzars): improve type-checking
type EquirectProgramEvents = 'render' | 'error.set' | 'error.clear';

interface EquirectProgram {
  setOptimalLevel?(): void;
  loadTiles?(): void;
}

class EquirectProgram {
  // todo: refactor this and use the actual image size
  static readonly MAX_EQUIRECT_WIDTH = 10000;
  static readonly cache: Map<string, Image> = new Map(); // path => image

  name: ProgramName;
  orderIndex = 0;

  _yaw = 0;
  _pitch = 0;
  _fov = Utils.toRad(120);

  alpha = 1.0;
  forcePreview = false;
  isPreloadPano = false;

  measureToolProgram?: MeasureToolProgram;

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;

  private vertexBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private aspectRatioUniformLocation: WebGLUniformLocation | null = null;
  private yawUniformLocation: WebGLUniformLocation | null = null;
  private pitchUniformLocation: WebGLUniformLocation | null = null;
  private fovUniformLocation: WebGLUniformLocation | null = null;
  private alphaUniformLocation: WebGLUniformLocation | null = null;

  private primaryTextureObject: WebGLTexture | null = null;
  private secondaryTextureObject: WebGLTexture | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private textureLoaded = false;
  private primaryLayoutPath = '';
  private secondaryLayoutPath = '';

  private ready = false;
  private _yawOffset = 0;

  private abortController = window.AbortController ? new AbortController() : null;
  private vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  private listeners: { [key: string]: ((payload) => void)[] } = {};

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'PanoProgram';
    this.renderer = renderer;
    this.name = name ?? 'PanoProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.primaryTextureObject = createTexture(webGLContext);

    this.drawToFramebufferTexture = this.drawToFramebufferTexture.bind(this);
  }

  get yaw(): number {
    return this._yaw;
  }

  set yaw(yaw: number) {
    this._yaw = yaw;
  }

  get fov(): number {
    return this._fov;
  }

  set fov(fov: number) {
    this._fov = fov;
  }

  get pitch(): number {
    return this._pitch;
  }

  set pitch(pitch: number) {
    this._pitch = pitch;
  }

  get yawOffset(): number {
    return this._yawOffset;
  }

  set yawOffset(yawOffset: number) {
    this._yawOffset = yawOffset;
  }

  /** Used for vt-video rendering (cleared before each transition) - for larger projects RAM usage is to high otherwise
   *  But wee need to keep the cache because the image is reused between equirect and transition rendering
   */
  static clearCache(): void {
    EquirectProgram.cache.clear();
  }

  init(): void {
    if (__USE_EQUIRECT__) {
      this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

      if (this.program) {
        this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');

        this.aspectRatioUniformLocation = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
        this.yawUniformLocation = this.gl.getUniformLocation(this.program, 'u_yaw');
        this.pitchUniformLocation = this.gl.getUniformLocation(this.program, 'u_pitch');
        this.fovUniformLocation = this.gl.getUniformLocation(this.program, 'u_fov');
        this.alphaUniformLocation = this.gl.getUniformLocation(this.program, 'u_alpha');

        this.vertexAttributes = [this.vertexAttribute];
      }

      this.ready = true;
    }
  }

  async reloadWithOptions(primaryOptions?: ImageBitmapOptions, secondaryOptions?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      EquirectProgram.cache.clear();

      await this.preload(this.primaryLayoutPath, primaryOptions);
      this.loadSecondaryTexture(this.secondaryLayoutPath, secondaryOptions);
    }
  }

  async setTexture(imagePath: string, options?: ImageBitmapOptions): Promise<WebGLTexture | null> {
    let image;

    if (!EquirectProgram.cache.has(imagePath)) {
      image = await Utils.fetchImage(imagePath, this.abortController, options);

      if (image) {
        EquirectProgram.cache.set(imagePath, image);
      }
    } else {
      image = EquirectProgram.cache.get(imagePath);
    }

    if (!image) {
      console.error(`Failed to set image texture: ${imagePath}`);
      return createTexture(this.gl);
    }

    this.gl.activeTexture(this.gl.TEXTURE0);
    const textureObject = createTexture(this.gl);
    setTextureFromImage(this.gl, textureObject, image);

    return textureObject;
  }

  async loadSecondaryTexture(secondaryLayoutPath: string, options?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      if (secondaryLayoutPath && this.secondaryLayoutPath !== secondaryLayoutPath) {
        this.secondaryTextureObject = await this.setTexture(secondaryLayoutPath, options);
        this.secondaryLayoutPath = secondaryLayoutPath;
        this.emit('render');
      }
    }
  }

  async preload(primaryLayoutPath: string, primaryOptions?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      // NOTE: only if createImageBitmap is supported and used, options are passed to the Engine in
      // assetConfig.equirectAssets.bitMapOptions.(primary | secondary)
      // This allows to reduce image size to make texture loading to the GPU faster with the cost of lower quality
      const bitMapOptions = {
        primary: primaryOptions || {},
      };

      const maxTextureSize = this.gl.getParameter(this.gl.MAX_TEXTURE_SIZE);

      if (maxTextureSize < EquirectProgram.MAX_EQUIRECT_WIDTH) {
        const resizeOptions: ImageBitmapOptions = {
          resizeWidth: maxTextureSize,
          resizeHeight: maxTextureSize / 2,
          resizeQuality: 'high',
        };
        bitMapOptions.primary = { ...bitMapOptions.primary, ...resizeOptions };
      }

      this.primaryLayoutPath = primaryLayoutPath;
      this.textureLoaded = false;

      this.primaryTextureObject = await this.setTexture(primaryLayoutPath, bitMapOptions.primary);

      this.textureLoaded = true;
      this.emit('render');
    }
  }

  destroy(): void {
    if (__USE_EQUIRECT__) {
      this.gl.deleteTexture(this.primaryTextureObject);
      this.gl.deleteTexture(this.secondaryTextureObject);
      this.abortPending();
      this.destroyEventEmitter();
      destroyProgram(this.gl, this.program);
    }
  }

  abortPending(): void {
    if (__USE_EQUIRECT__) {
      if (this.abortController) {
        this.abortController.abort();
      }
    }
  }

  subscribe(event: EquirectProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  unsubscribe(event: EquirectProgramEvents, fn: (payload) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  render(): void {
    if (!__USE_EQUIRECT__) return;
    if (!this.gl || !this.ready) return;

    const { renderer } = this;
    if (renderer.renderMode !== 'pano') return;

    // Transition rendering
    if (renderer.isInTransition) {
      const isDoublePanoBlend = !renderer.transitionConnected || renderer.watermarkInterrupted;

      if (isDoublePanoBlend) {
        if (this.isPreloadPano) this.alpha = renderer.transitionProgress;
        this.draw(true);
        if (this.isPreloadPano) renderer.render();
      }

      return;
    }

    const { cameraMoved } = renderer;

    // Normal rendering with measure tool
    if (this.measureToolProgram) {
      renderer.nextDrawMoved = cameraMoved;

      if (cameraMoved) {
        renderer.shouldDrawAfterMove = true;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.needsToDrawFb) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.shouldDrawAfterMove) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.shouldDrawAfterMove = false;
      }

      return;
    }

    // Normal rendering
    this.draw(cameraMoved);
  }

  draw(moved = false): void {
    if (!__USE_EQUIRECT__) return;
    if (!this.gl || !this.ready) return;
    if (!this.primaryTextureObject || !this.textureLoaded) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.aspectRatioUniformLocation, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniformLocation, this.yaw + this.yawOffset);
    this.gl.uniform1f(this.pitchUniformLocation, this.pitch);
    this.gl.uniform1f(this.fovUniformLocation, this.fov);

    this.gl.uniform1f(this.alphaUniformLocation, this.alpha);

    this.gl.activeTexture(this.gl.TEXTURE0);

    if (!moved && this.secondaryTextureObject) {
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.secondaryTextureObject);
    } else {
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.primaryTextureObject);
    }

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  private drawToFramebufferTexture(): void {
    this.draw(this.renderer.nextDrawMoved);
  }

  /** Destroy emitter by removing all subscribers */
  private destroyEventEmitter(): void {
    this.listeners = {};
  }

  private emit(event: EquirectProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }
}

export default EquirectProgram;
