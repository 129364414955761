import type { AssetConfig, CaptionFont } from '@g360/vt-types';
import { getTextScaleForVideoEditor } from '@g360/vt-utils';
import noop from 'lodash/noop';
import type opentype from 'opentype.js';
import { Mixin } from 'ts-mixer';

import type Renderer from '../../../mixins/Renderer';
import type { CaptionParams } from '../../../types/internal';
import TextProgram2D from '../TextProgram2D';
import { getTextBoundingBoxClipSpace, getTextColor } from './utils';

type AnimationParams = {
  type: 'fadeIn' | 'fadeOut';
  duration: number;
};

const defaultFont: CaptionFont = 'RobotoItalicRegular';

class CaptionProgram extends Mixin(TextProgram2D) {
  fontData: { [key in CaptionFont]: opentype.Font } | null = null;

  protected renderer: Renderer;
  protected fonts: { [key in CaptionFont]: string } = {
    RobotoItalicRegular: 'video/fonts/Roboto_Italic/Roboto-Italic.ttf',
    CourgetteRegular: 'video/fonts/Courgette/Courgette-Regular.ttf',
    BebasNeueRegular: 'video/fonts/Bebas_Neue/BebasNeue-Regular.ttf',
    MontagaRegular: 'video/fonts/Montaga/Montaga-Regular.ttf',
    ComfortaaRegular: 'video/fonts/Comfortaa/Comfortaa-Regular.ttf',
    CourierPrimeRegular: 'video/fonts/Courier_Prime/CourierPrime-Regular.ttf',
  };

  private captionParams: CaptionParams = {
    lightMode: 'dark',
    text: '',
    align: 'bottomCenter',
    font: defaultFont,
    animationDuration: 0,
  };

  private clipSpaceCoords: [number, number, number, number] = [0.0, 0.0, 0.0, 0.0];
  private margin: [number, number, number, number] = [40, 62, 52, 62];

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    assetConfig: AssetConfig,
    renderer: Renderer
  ) {
    super(webGLContext, canvas, assetConfig);

    this.renderer = renderer;
  }

  public updateText(captionParams: CaptionParams): void {
    if (!this.fontData) {
      this.subscribeOnce('fonts.loaded', () => this.updateText(captionParams));
      return;
    }

    this.captionParams = captionParams;

    this.update({
      text: captionParams.text ?? '',
      font: captionParams.font ?? defaultFont,
      fontSize: captionParams.fontSize ?? 72,
      fontColor: captionParams.lightMode ? getTextColor(captionParams.lightMode) : [0, 0, 0, 1],
      clipSpaceOffset: [0.0, 0.0],
    });

    if (!this.boundingBox) {
      return;
    }

    const scale = getTextScaleForVideoEditor(this.canvas.getBoundingClientRect());
    const scaledMargin = this.margin.map((m) => m * scale) as [number, number, number, number];

    this.clipSpaceCoords = getTextBoundingBoxClipSpace(
      this.captionParams.align ?? 'middleCenter',
      scaledMargin,
      this.boundingBox,
      [this.canvas.clientWidth, this.canvas.clientHeight]
    );

    this.textParams.clipSpaceOffset = [this.clipSpaceCoords[0], this.clipSpaceCoords[1]];

    if (this.captionParams?.animationDuration && this.captionParams?.animationDuration > 0) {
      this.fadeAnimation({ type: 'fadeIn', duration: this.captionParams?.animationDuration });
    }
  }

  public hide(noAnimation = false) {
    if (noAnimation) {
      this.update({ ...this.textParams, text: '' });
      return;
    }

    this.fadeAnimation({ type: 'fadeOut', duration: this.captionParams?.animationDuration ?? 0 }, () => {
      this.textParams.text = '';
    });
  }

  public fadeAnimation({ type, duration }: AnimationParams, finishedCallback?: () => void) {
    const maxAlpha = this.textParams.fontColor[3];
    this.textParams.fontColor[3] = type === 'fadeIn' ? 0 : maxAlpha;
    this.renderer.startAnimation(
      'captionAnimation', // starting animations with same type will stop previous one (unless it's finished on it's own)
      (progress) => {
        this.textParams.fontColor[3] = maxAlpha * (type === 'fadeOut' ? maxAlpha - progress : progress);
      },
      finishedCallback ?? noop,
      'linear',
      duration
    );
  }

  public getClipspaceCoords() {
    return this.clipSpaceCoords;
  }

  protected getTextMaxWidth() {
    return this.canvas.clientWidth - 100;
  }
}

export default CaptionProgram;
