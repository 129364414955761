import type { AssetConfig, TourConfig } from '@g360/vt-types';
import { toRad, transposeM4 } from '@g360/vt-utils';

import { MAX_FOV_RAD, transitionSettings } from '../../common/Globals';
import RendererDebug from '../../common/RendererDebug';
import { getPerspectiveMatrix } from '../../common/Utils';
import getRotationMatrixZX from '../../common/Utils/matrixUtils/getRotationMatrixZX';
import { disableVertexAttributes, enableVertexAttributes, initShaders, loadShaders } from '../../common/webglUtils';
import { Utils } from '../../index';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import Sphere from './Sphere';
import fragmentShaderSource from './sphere.fs.glsl';
import vertexShaderSource from './sphere.vs.glsl';
import TransitionBlendProgram from './TransitionBlendProgram';

class SphereProgram {
  name: ProgramName;
  orderIndex = 0;
  secondOrderIndex = 0;

  fov = 0;
  pitch = 0;
  yaw = 0;

  cameraPosition: number[] = [0, 0, 0];
  transitionBlendProgram: TransitionBlendProgram;

  mainTexLocation: WebGLUniformLocation | null = null;
  depthTexLocation: WebGLUniformLocation | null = null;
  matrixLocalRotationLocation: WebGLUniformLocation | null = null;
  matrixScaleLocation: WebGLUniformLocation | null = null;
  matrixCameraPosLocation: WebGLUniformLocation | null = null;
  geometryIndices: Uint16Array = new Uint16Array();
  abortController = window.AbortController ? new AbortController() : null;

  tourConfig?: TourConfig;
  assetConfig: AssetConfig;

  spheres: { [key: string]: Sphere } = {}; // spheres are never destroyed (but textures of are unloaded from unused spheres)

  readonly sphereScale = 1300;

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;

  private usingLocalCubemaps = false;
  private inverseDepthMapY = false;

  private alpha = 0; // here for legacy reasons, not used in rendering
  private showCurrent = true;
  private showNext = true;
  private currentSpherePanoKey = ''; // "current" & "next" when in transition; after the transition should be read as "previous" & "current" (changed shortly before the transition)
  private nextSpherePanoKey = '';
  private currentSphere?: Sphere; // cached reference
  private nextSphere?: Sphere;

  private baseFov = 0; // current FOV, ignoring zoom (is constant)
  private baseVFov = 0; // current vertical FOV, ignoring zoom (changes depending on screen size)

  private normalLocation = 0;
  private uvLocation = 0;
  private uvDepthLocation = 0;
  private matrixPerspectiveLocation: WebGLUniformLocation | null = null;
  private matrixRotationLocation: WebGLUniformLocation | null = null;
  private vertexBuffer: WebGLBuffer | null = null;
  private normalBuffer: WebGLBuffer | null = null;
  private vertIndexBuffer: WebGLBuffer | null = null;
  private uvBuffer: WebGLBuffer | null = null;
  private uvDepthBuffer: WebGLBuffer | null = null;
  private textureCoordsBuffer: WebGLBuffer | null = null;
  private geometry: Float32Array = new Float32Array();
  private uvMap: Float32Array = new Float32Array();
  private uvMapDepth: Float32Array = new Float32Array();
  private normals: Float32Array = new Float32Array();
  private lastRenderTimeStamp = -1;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    assetConfig: AssetConfig,
    usingLocalCubemaps: boolean,
    inverseDepthMapY: boolean,
    name?: ProgramName
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.assetConfig = assetConfig;
    this.usingLocalCubemaps = usingLocalCubemaps;
    this.inverseDepthMapY = inverseDepthMapY;
    this.name = name ?? 'SphereProgram';

    this.baseFov = MAX_FOV_RAD;
    this.baseVFov = Utils.horizontalToVerticalFovRad(this.baseFov, {
      width: this.gl.drawingBufferWidth,
      height: this.gl.drawingBufferHeight,
    });

    this.renderDoubleSphereBlendRender = this.renderDoubleSphereBlendRender.bind(this);

    this.transitionBlendProgram = new TransitionBlendProgram(this.gl, this.canvas, this.renderer, this);
  }

  // when engine is loaded
  setTourConfig(tourConfig: TourConfig): void {
    this.tourConfig = tourConfig;
  }

  createGeometry(size): void {
    const w = size;
    const h = size;

    /*
      max num of vertices to draw in single call == 65k (if using indexed vertices and drawElements() )
      255 * 255 - max
      175*175 - indistinguishable from max, very diminished returns from this point (but still - white doorframes near camera produce visible saw-tooth pattern, higher resolution still produces sawteeth, just of higher frequency, can't objectively say which looks better )

          Geometry:
          1,-1  →  -1,-1
           ↓
          1,1     -1,1

          UVs:
          0,0  →  1,0
           ↓
          0,1     1,1

          Triangles (each quad):
          -1,1    1,-1   -1,-1
          -1,-1  -1,1     1,1
   */
    let v = 0;
    let indicesIndex = 0; // used for keeping track of pointer in geometry indices array

    const totalVertices = w * h;
    this.geometry = new Float32Array(totalVertices * 3); // times 3 because each vertex consists of x, y, z coordinates
    this.geometryIndices = new Uint16Array((w - 1) * (h - 1) * 6); // each rectangle creates 2 triangles which have 3 vertices each
    this.uvMap = new Float32Array(totalVertices * 2); // times 2 because each uv mapping consists of u, v coordinates
    this.uvMapDepth = new Float32Array(totalVertices * 2); // same as above
    this.normals = new Float32Array(totalVertices * 3); // times 3 because each normal consists of x, y, z components

    for (let i = 0; i < h; i += 1) {
      for (let j = 0; j < w; j += 1) {
        let x = (j / (w - 1)) * 2 - 1;
        let y = (i / (h - 1)) * 2 - 1;
        const z = -1;

        // quick fix for the visible seems between faces in large rooms:
        // make the face bigger by moving the very edge vertices outward
        // this is only needed for top and bottom, but since they all share single geometry, it's done to every face
        // maybe better solution would be to blend the edges of the depthmaps after the render (only top and bottom)
        if (i === 0 || i === h - 1) {
          y *= 1.01;
        }
        if (j === 0 || j === w - 1) {
          x *= 1.01;
        }

        const magnitude = Math.sqrt(x * x + y * y + 1);
        const normX = x / magnitude;
        const normY = y / magnitude;
        const normZ = z / magnitude;

        const vertexIndex = v * 3;
        this.geometry[vertexIndex] = normX;
        this.geometry[vertexIndex + 1] = normY;
        this.geometry[vertexIndex + 2] = normZ;

        this.normals[vertexIndex] = normX;
        this.normals[vertexIndex + 1] = normY;
        this.normals[vertexIndex + 2] = normZ;

        if (j < w - 1 && i < h - 1) {
          // 2 triangles per quad
          this.geometryIndices[indicesIndex] = v + w;
          this.geometryIndices[indicesIndex + 1] = v + 1;
          this.geometryIndices[indicesIndex + 2] = v;
          this.geometryIndices[indicesIndex + 3] = v + w;
          this.geometryIndices[indicesIndex + 4] = v + 1 + w;
          this.geometryIndices[indicesIndex + 5] = v + 1;
          // indicesIndex should not be calculated from v, b/c sometimes values are skipped in the middle
          indicesIndex += 6;
        }

        const uvIndex = v * 2;
        const uvx = 1 - j / (w - 1);
        const uvy = 1 - i / (h - 1);

        this.uvMap[uvIndex] = uvx;
        this.uvMap[uvIndex + 1] = uvy;
        this.uvMapDepth[uvIndex] = uvx;
        this.uvMapDepth[uvIndex + 1] = this.inverseDepthMapY ? 1 - uvy : uvy; // inverse UV map Y for depth; needed when DMs are rendered directly to texture)

        v += 1;
      }
    }
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);
    if (this.program) {
      this.normalLocation = this.gl.getAttribLocation(this.program, 'a_normal');
      this.uvLocation = this.gl.getAttribLocation(this.program, 'a_uv');
      this.uvDepthLocation = this.gl.getAttribLocation(this.program, 'a_uv_depth');

      this.mainTexLocation = this.gl.getUniformLocation(this.program, 'u_sampler_main_tex');
      this.depthTexLocation = this.gl.getUniformLocation(this.program, 'u_sampler_depth_tex');
      this.matrixPerspectiveLocation = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.matrixRotationLocation = this.gl.getUniformLocation(this.program, 'u_rotate');
      this.matrixLocalRotationLocation = this.gl.getUniformLocation(this.program, 'u_localRotate');
      this.matrixScaleLocation = this.gl.getUniformLocation(this.program, 'u_scale');
      this.matrixCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_cameraPos');

      this.vertexBuffer = this.gl.createBuffer();
      this.normalBuffer = this.gl.createBuffer();
      this.vertIndexBuffer = this.gl.createBuffer();
      this.uvBuffer = this.gl.createBuffer();
      this.uvDepthBuffer = this.gl.createBuffer();
      this.textureCoordsBuffer = this.gl.createBuffer();

      this.vertexAttributes = [this.normalLocation, this.uvLocation, this.uvDepthLocation];
      this.createGeometry(transitionSettings.geometryResolution);
      this.prepStage();
    }

    this.transitionBlendProgram.init();
  }

  prepStage(): void {
    loadShaders(this.gl, this.program, false);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.geometry, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.normalBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.normals, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMap, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvDepthBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMapDepth, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, this.geometryIndices, this.gl.STATIC_DRAW);
  }

  destroy(): void {
    Object.values(this.spheres).forEach((sphere) => {
      sphere.destroy();
    });
  }

  unloadUnusedSpheres(): void {
    Object.values(this.spheres).forEach((sphere) => {
      if (sphere.panoKey !== this.currentSpherePanoKey && sphere.panoKey !== this.nextSpherePanoKey) {
        sphere.destroy(true); // destroys only easily-recreatable GL textures and resets them ready to load them anew if drawn again
      }
    });
  }

  render(frameTimeStamp: number): void {
    const { gl } = this;

    if (!gl) return;

    const { renderer } = this;

    // Transition rendering
    // First SphereProgram render call
    if (frameTimeStamp !== this.lastRenderTimeStamp) {
      if (renderer.isInTransition) {
        const { transitionBlendProgram } = this;

        // NOTE: If watermark is interrupted, force the blend transition. We could remove this limitation if we could also force the scene blend transition into low resolution mode
        if (!renderer.transitionConnected || renderer.watermarkInterrupted) {
          this.lastRenderTimeStamp = frameTimeStamp;
          return;
        }

        this.showCurrent = true;
        this.showNext = false;
        this.draw();

        gl.clear(gl.DEPTH_BUFFER_BIT); // reset between 2 sphere drawings

        renderer.renderToTextureWithFunction(
          this.renderDoubleSphereBlendRender,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          transitionBlendProgram.tex,
          !renderer.safeMode
        );

        transitionBlendProgram.render();
      }

      this.lastRenderTimeStamp = frameTimeStamp;
      return;
    }

    // Debug sphere rendering
    // Second SphereProgram render call
    if (!__DEV_PANEL__) return;
    if (!RendererDebug.runDebugRender.sphere) return;

    this.draw();
    this.lastRenderTimeStamp = frameTimeStamp;
  }

  removeAllSpheres(): void {
    this.nextSpherePanoKey = '';
    this.currentSpherePanoKey = '';
    this.nextSphere = undefined;
    this.currentSphere = undefined;
    this.destroy();
  }

  makeSphere(panoKey: string | [string, string]): void {
    const isSubScene = Array.isArray(panoKey);

    if (this.tourConfig) {
      const scene = isSubScene
        ? this.tourConfig.scenes[panoKey[0]].subScenes?.[panoKey[1]]
        : this.tourConfig.scenes[panoKey];

      if (!scene) return;

      const yawOffset = -toRad(scene.camera[3]);
      const position = [scene.camera[0], scene.camera[1]];
      const outside = scene.outside as boolean;
      this.spheres[scene.sceneKey] = new Sphere(
        this.gl,
        this,
        scene.sceneKey,
        yawOffset,
        position,
        outside,
        this.usingLocalCubemaps
      );
    }
  }

  setNextSphere(panoKey: string | [string, string]): void {
    const isSubScene = Array.isArray(panoKey);

    if (!isSubScene && !this.tourConfig?.scenes[panoKey]) {
      return;
    }

    if (isSubScene) {
      // Navigating to main scene of subScene group
      if (panoKey[0] === panoKey[1] && !this.tourConfig?.scenes[panoKey[0]]) return;

      // Navigating to subScene of subScene group
      if (panoKey[0] !== panoKey[1] && !this.tourConfig?.scenes[panoKey[0]]?.subScenes?.[panoKey[1]]) return;
    }

    this.currentSpherePanoKey = this.nextSpherePanoKey;
    this.nextSpherePanoKey = Array.isArray(panoKey) ? panoKey[1] : panoKey;

    if (Object.keys(this.spheres).length === 0) {
      if (this.tourConfig) {
        Object.values(this.tourConfig.scenes).forEach((scene) => {
          this.makeSphere(scene.sceneKey);
        });
      }
    }

    if (!this.spheres[this.nextSpherePanoKey]) {
      this.makeSphere(panoKey);
    }

    this.currentSphere = this.spheres[this.currentSpherePanoKey];
    this.nextSphere = this.spheres[this.nextSpherePanoKey];

    this.unloadUnusedSpheres();
  }

  setNextSphereCameraOffsetForTransition(offset = [0, 0, 0]): void {
    if (this.nextSphere) this.nextSphere.positionOffsetForTransition = offset;
  }

  resetCameraOffsetForTransition(): void {
    if (this.nextSphere) this.nextSphere.positionOffsetForTransition = [0, 0, 0];
    if (this.currentSphere) this.currentSphere.positionOffsetForTransition = [0, 0, 0];
  }

  async preloadSpheres(): Promise<void> {
    loadShaders(this.gl, this.program);
    const promises: Promise<void>[] = [];

    for (let i = 0; i < 6; i += 1) {
      if (this.currentSphere?.sphereFaces[i]) {
        promises.push(this.currentSphere.sphereFaces[i].loadImages());
      }
      if (this.nextSphere?.sphereFaces[i]) {
        promises.push(this.nextSphere.sphereFaces[i].loadImages());
      }
    }

    await Promise.all(promises);
  }

  private draw(): void {
    loadShaders(this.gl, this.program, false);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.BACK);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.depthFunc(this.gl.LESS);

    // between-walls colors
    this.gl.clearColor(0.7593, 0.7593, 0.7593, 1);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    const drawDistance = 10000;
    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

    const perspectiveMatrix = getPerspectiveMatrix(this.fov, size, 1, drawDistance);

    this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(transposeM4(perspectiveMatrix)));

    const rotationMatrix = getRotationMatrixZX(this.yaw, toRad(-90) + this.pitch);
    this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(rotationMatrix));

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.normalBuffer);
    this.gl.vertexAttribPointer(this.normalLocation, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.vertexAttribPointer(this.uvLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvDepthBuffer);
    this.gl.vertexAttribPointer(this.uvDepthLocation, 2, this.gl.FLOAT, false, 0, 0);

    if (this.showCurrent && this.currentSphere) {
      this.currentSphere.draw();
    }

    if (this.showNext && this.nextSphere && this.nextSpherePanoKey !== this.currentSpherePanoKey) {
      this.nextSphere.draw();
    }

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  private renderDoubleSphereBlendRender(): void {
    this.showCurrent = false;
    this.showNext = true;
    this.draw();
  }
}

export default SphereProgram;
