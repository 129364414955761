var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Utils } from '@g360/vt-engine';
import { base64Encode, ErrorLogger } from '@g360/vt-utils';
import { useEffect, useState } from 'react';
import { getDataFromSceneKey, normalizeAngle } from '../../../../utils/misc';
import CheckBox from '../../../common/CheckBox';
import Label from '../../../common/Label';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import IconButton from '../common/IconButton';
import CopyIcon from '../icons/CopyIcon';
const ShareTour = ({ addTopMargin = true }) => {
    const [shareView, setShareView] = useState(false);
    const [viewQuery, setViewQuery] = useState('');
    const [rawViewQuery, setRawViewQuery] = useState('');
    const t = useTranslation();
    const { engine, analytics } = useGlobalContext();
    const { layoutService, tourConfigService } = useServices();
    const { projectUrl } = useReactive(tourConfigService, ['projectUrl']);
    const twTopMargin = addTopMargin ? 'mt-8' : '';
    const handleShareViewChange = (evt) => {
        setShareView(evt.target.checked);
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', 'Share current view', {
            share_view: shareView,
            raw_view_query: rawViewQuery,
        });
    };
    let link = projectUrl || '';
    if (shareView) {
        link += `?v=${viewQuery}`;
    }
    const copyLinkFallback = () => {
        // There is a chance
        try {
            const el = document.createElement('textarea');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            layoutService.addToastMessage(t('share.link-copy-ok'));
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            layoutService.addToastMessage(t('share.link-copy-error'));
            ErrorLogger.captureMessage('Share link copy failed');
        }
    };
    const handleCopyLink = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (typeof window === 'undefined')
                return;
            yield navigator.clipboard.writeText(link);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', 'Copy', {
                share_view: shareView,
                raw_view_query: rawViewQuery,
            });
            layoutService.addToastMessage(t('share.link-copy-ok'));
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            copyLinkFallback();
        }
    });
    useEffect(() => {
        if (!engine)
            return;
        const pos = { yaw: Utils.toDeg(engine.getYaw()), pitch: Utils.toDeg(engine.getPitch()) };
        const offset = Utils.toDeg(engine.getYawOffset());
        const view = [pos.pitch, normalizeAngle(pos.yaw + offset)].map((v) => Math.round(v)).join(',');
        const currentScene = engine.getActiveSceneKey();
        const { cameraModel, cameraNumber, projectNumber, panoNumber } = getDataFromSceneKey(currentScene);
        const shortId = `${cameraNumber}${cameraModel || ''}-${projectNumber}-${panoNumber}`;
        const rawQuery = `${shortId},${view}`;
        setRawViewQuery(rawQuery);
        setViewQuery(base64Encode(rawQuery));
    }, [engine]);
    return (_jsxs("div", Object.assign({ className: `mb-8 rounded-lg border-2 border-modal-gray px-8 py-6 ${twTopMargin}` }, { children: [_jsxs("div", Object.assign({ className: "flex min-w-0 flex-row" }, { children: [_jsxs("div", Object.assign({ className: "flex min-w-0 flex-col font-bold" }, { children: [_jsx(Label, Object.assign({ size: "small", selectable: false }, { children: t('share.share-link') })), _jsx(Label, Object.assign({ className: "mr-8 mt-0.5 min-w-0 truncate", size: "medium", testId: "link-label" }, { children: link }))] })), _jsx("span", { className: "grow" }), _jsx(IconButton, Object.assign({ onClick: handleCopyLink, testId: "copy-icon-btn" }, { children: _jsx(CopyIcon, { className: "h-6 w-6" }) }))] })), _jsx(CheckBox, { className: "mt-3", label: t('share.share-view'), checked: shareView, onChange: handleShareViewChange })] })));
};
export default ShareTour;
