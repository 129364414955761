/* eslint-disable no-continue */
import debounce from 'lodash/debounce';
import CanvasMiniMapRenderer from './CanvasMiniMapRenderer';
import { drawImage, fpPixels2CanvasPixels } from './utils/drawing';
import { findClosestDistanceInGroup, groupPins } from './utils/grouping';
import { getActivePinSvg, getActivePinTextSvg, getPinSvg, pinMultiOffset, svg2Img } from './utils/svg';
export default class PinsRenderer {
    constructor({ canvas, ctx, assetPath, floorPlanSize, pins, startingSceneKey, accentColor, onSceneKeyHoverChanged, onPinGroupHoverChanged, getNextActivePinLabel, forceRenderParent, }) {
        this.devicePixelRatio = 0;
        this.lastYaw = -999;
        this.lastScale = -999;
        this.lastMousePos = { x: -999, y: -999 };
        this.floorPlanCenter = { x: -999, y: -999 };
        this.hoveredPinGroupIndex = -1;
        /** to check if need to redraw */
        this.lastHoveredPinGroupIndex = -1;
        /** if hovering a single-pin-group */
        this.hoveredSceneKey = null;
        this.forceRenderNextFrame = false;
        this.activePinSceneKey = null;
        /** cache value when active pin is drawn, invalidate it whenever something changes */
        this.activePinGroupIndex = -1;
        /** active pin animation: pin index that is being animated from */
        this.activePinIndexAnimationA = -1;
        /** pin index that is being animated to */
        this.activePinIndexAnimationB = -1;
        /** 0..1 */
        this.activePinAnimationProgress = 0;
        this.pinGroups = [];
        /** ridiculously large number indicates that pins have not been grouped yet */
        this.pinsGroupedAtScale = 9999;
        this.needToRedraw = false;
        this.debouncedGroupPins = debounce(() => {
            this.groupPins();
        }, PinsRenderer.pinGroupDebounceTime);
        this.canvas = canvas;
        this.ctx = ctx;
        this.assetPath = assetPath;
        this.floorPlanSize = floorPlanSize;
        this.onPinGroupHoverChangedInMinimap = onPinGroupHoverChanged;
        this.onSceneKeyHoverChangedInMinimap = onSceneKeyHoverChanged;
        this.getNextActivePinLabel = getNextActivePinLabel;
        this.forceRenderParent = forceRenderParent;
        if (!this.floorPlanSize)
            throw new Error('FloorPlanRenderer::floorPlanSize is undefined');
        this.onResize();
        this.pins = [];
        for (let i = 0; i < pins.length; i += 1) {
            this.pins.push({
                posRatio: Object.assign({}, pins[i].posRatio),
                posFloorPlan: {
                    x: pins[i].posRatio.x * this.floorPlanSize.width,
                    y: pins[i].posRatio.y * this.floorPlanSize.height,
                },
                posCanvas: { x: 0, y: 0 },
                key: pins[i].key,
            });
        }
        this.groupPins(); // instantly group pins
        this.activePinSceneKey = startingSceneKey;
        this.activePinGroupIndex = -1;
        // SVG images::create once
        if (PinsRenderer.renderedSvgs.size === 0) {
            const d1 = PinsRenderer.pinDiameter;
            const d2 = PinsRenderer.pinDiameter + pinMultiOffset;
            const d3 = PinsRenderer.pinDiameter + pinMultiOffset * 2;
            const c = 'white';
            // pin icons are created for 1, 2 and 3 pins in a group
            // each group is single image, so that while animating opacity, group doesn't become self-transparent
            // (seeing individual rings through the group, that are normally hidden)
            const pinBase1Img = svg2Img(getPinSvg('#A0A6AE', c, 1), d1, d1, () => {
                PinsRenderer.renderedSvgs.set('pinBase1', pinBase1Img);
            });
            const pinBase2Img = svg2Img(getPinSvg('#A0A6AE', c, 2), d2, d1, () => {
                PinsRenderer.renderedSvgs.set('pinBase2', pinBase2Img);
            });
            const pinBase3Img = svg2Img(getPinSvg('#A0A6AE', c, 3), d3, d1, () => {
                PinsRenderer.renderedSvgs.set('pinBase3', pinBase3Img);
            });
            const pinFocus1Img = svg2Img(getPinSvg('#3b3d3f', c, 1), d1, d1, () => {
                PinsRenderer.renderedSvgs.set('pinFocus1', pinFocus1Img);
            });
            const pinFocus2Img = svg2Img(getPinSvg('#3b3d3f', c, 2), d2, d1, () => {
                PinsRenderer.renderedSvgs.set('pinFocus2', pinFocus2Img);
            });
            const pinFocus3Img = svg2Img(getPinSvg('#3b3d3f', c, 3), d3, d1, () => {
                PinsRenderer.renderedSvgs.set('pinFocus3', pinFocus3Img);
            });
            const pinVisited1Img = svg2Img(getPinSvg('#D7DADD', c, 1), d1, d1, () => {
                PinsRenderer.renderedSvgs.set('pinVisited1', pinVisited1Img);
            });
            const pinVisited2Img = svg2Img(getPinSvg('#D7DADD', c, 2), d2, d1, () => {
                PinsRenderer.renderedSvgs.set('pinVisited2', pinVisited2Img);
            });
            const pinVisited3Img = svg2Img(getPinSvg('#D7DADD', c, 3), d3, d1, () => {
                PinsRenderer.renderedSvgs.set('pinVisited3', pinVisited3Img);
            });
        }
        // SVG images::create once per accent color
        this.renderAccentedPinImages(accentColor);
        this.renderActivePinLabelImage(startingSceneKey);
    }
    static getRenderedSvg(name, groupSize = '') {
        const svg = PinsRenderer.renderedSvgs.get(`${name}${groupSize}`);
        if (!svg)
            throw new Error(`PinsRenderer::getRenderedSvgs::svg ${name} not found`); // this should never happen
        return svg !== null && svg !== void 0 ? svg : '';
    }
    /**
     * Parent class is calling this method on mouse move
     */
    inputMouseMove(mousePos) {
        var _a;
        this.lastMousePos = mousePos;
        let hoveredPinGroupIndex = -1;
        let minDistanceSquared = Math.pow((PinsRenderer.pinDiameterHover / 2), 2);
        // find first visual pin in a group that is hovered
        for (let i = 0; i < this.pinGroups.length; i += 1) {
            const group = this.pinGroups[i];
            for (let j = 0; j < group.iconOffsetPosCanvas.length; j += 1) {
                const distanceSqrd = Math.pow((mousePos.x - group.posCanvas.x - group.iconOffsetPosCanvas[j].x), 2) +
                    Math.pow((mousePos.y - group.posCanvas.y - group.iconOffsetPosCanvas[j].y), 2);
                if (distanceSqrd < minDistanceSquared) {
                    minDistanceSquared = distanceSqrd;
                    hoveredPinGroupIndex = i;
                    break;
                }
            }
            if (hoveredPinGroupIndex !== -1)
                break;
        }
        const hoveredGroup = hoveredPinGroupIndex === -1 ? null : this.pinGroups[hoveredPinGroupIndex];
        const hoveredGroupLength = (_a = hoveredGroup === null || hoveredGroup === void 0 ? void 0 : hoveredGroup.pins.length) !== null && _a !== void 0 ? _a : 0;
        // if we are hovering a single-pin-group, only then we are hovering a scene
        let hoveredSceneKey = (hoveredGroupLength === 1 ? hoveredGroup === null || hoveredGroup === void 0 ? void 0 : hoveredGroup.pins[0].key : null) || null;
        // check if active pin is in the hovered group
        const hoveringActivePinGroup = hoveredGroup && hoveredGroup.pins.findIndex((pin) => pin.key === this.activePinSceneKey) !== -1;
        // don't allow to hover this group if it consists only of active pin
        if (hoveringActivePinGroup && (hoveredGroup === null || hoveredGroup === void 0 ? void 0 : hoveredGroup.pins.length) === 1) {
            hoveredPinGroupIndex = -1;
            hoveredSceneKey = null;
        }
        if (hoveredPinGroupIndex !== this.hoveredPinGroupIndex) {
            this.hoveredPinGroupIndex = hoveredPinGroupIndex;
            this.onPinGroupHoverChangedInMinimap();
        }
        if (hoveredSceneKey !== this.hoveredSceneKey) {
            this.hoveredSceneKey = hoveredSceneKey;
            this.onSceneKeyHoverChangedInMinimap(hoveredSceneKey);
        }
    }
    /**
     * Parent class is calling this method if hover changes in scene
     *
     * @returns true if hovered pin index has changed
     */
    inputPanoHovered(sceneKey) {
        const hoveredPinGroupIndex = sceneKey !== null
            ? this.pinGroups.findIndex((group) => group.pins.findIndex((pin) => pin.key === sceneKey) !== -1)
            : -1;
        if (hoveredPinGroupIndex !== this.hoveredPinGroupIndex) {
            this.hoveredPinGroupIndex = hoveredPinGroupIndex;
            if (hoveredPinGroupIndex === -1) {
                return true;
            }
        }
        return false;
    }
    calculatePins(yaw, scale, floorPlanCenter) {
        // recalculates pixel position if input is different from last time
        this.needToRedraw = this.tryRecalculatePinPixels(yaw, scale, floorPlanCenter);
        if (this.hoveredPinGroupIndex !== this.lastHoveredPinGroupIndex) {
            this.needToRedraw = true;
            this.lastHoveredPinGroupIndex = this.hoveredPinGroupIndex;
        }
        return this.needToRedraw;
    }
    render(activePinOpacity, pinsOpacity) {
        if (activePinOpacity <= 0 && pinsOpacity <= 0)
            return; // nothing to render
        this.recheckActivePinGroupIndex();
        this.ctx.globalAlpha = pinsOpacity;
        this.drawPins('non-hovered-only');
        this.ctx.globalAlpha = activePinOpacity;
        this.drawActivePinLabel();
        this.ctx.globalAlpha = pinsOpacity;
        this.drawPins('hovered-only');
        this.ctx.globalAlpha = activePinOpacity;
        this.drawActivePin();
        this.ctx.globalAlpha = 1;
    }
    forceRender() {
        this.forceRenderNextFrame = true;
    }
    isHoveringPinGroup() {
        return this.hoveredPinGroupIndex !== -1;
    }
    getHoveredPinGroupIndex() {
        return this.hoveredPinGroupIndex;
    }
    /**
     * @returns hovered pin group scene key if it is a single-pin-group, otherwise undefined
     */
    getHoveredPinGroupKey() {
        if (this.hoveredPinGroupIndex === -1)
            return undefined;
        if (this.pinGroups[this.hoveredPinGroupIndex].pins.length === 1)
            return this.pinGroups[this.hoveredPinGroupIndex].pins[0].key;
        return undefined;
    }
    onSceneTransitionStart(fromScene, toScene) {
        const isToSubScene = Array.isArray(toScene);
        const isFromSubScene = Array.isArray(fromScene);
        const inSameSceneGroup = (isToSubScene && isFromSubScene && fromScene[0].sceneKey === toScene[0].sceneKey) ||
            (!isFromSubScene && isToSubScene && fromScene.sceneKey === toScene[0].sceneKey);
        if (inSameSceneGroup) {
            // No animation needed, because a subScene group is represented by a single pin
            return;
        }
        const mainTargetScene = isToSubScene ? toScene[0] : toScene;
        const mainSourceScene = isFromSubScene ? fromScene[0] : fromScene;
        this.activePinSceneKey = null;
        this.activePinGroupIndex = -1;
        this.activePinIndexAnimationA = this.pins.findIndex((pin) => pin.key === mainSourceScene.sceneKey);
        this.activePinIndexAnimationB = this.pins.findIndex((pin) => pin.key === mainTargetScene.sceneKey);
        this.activePinAnimationProgress = 0;
        this.renderActivePinLabelImage(mainTargetScene.sceneKey);
    }
    onSceneTransitionUpdate(progress) {
        this.activePinAnimationProgress = progress;
    }
    onSceneTransitionEnd(toScene) {
        const isSubScene = Array.isArray(toScene);
        this.activePinSceneKey = isSubScene ? toScene[0].sceneKey : toScene.sceneKey;
        this.activePinGroupIndex = -1;
        this.activePinIndexAnimationA = -1;
        this.activePinIndexAnimationB = -1;
        this.activePinAnimationProgress = 0;
    }
    getPinCanvasPosition(sceneKey) {
        const pinIndex = this.pins.findIndex((pin) => pin.key === sceneKey);
        return pinIndex !== -1 ? this.pins[pinIndex].posCanvas : undefined;
    }
    getPinGroupFloorPlanPosition(groupIndex) {
        return this.pinGroups[groupIndex].posFloorPlan;
    }
    getScaleWhereThisGroupWillUngroup(groupIndex) {
        const group = this.pinGroups[groupIndex];
        const closestDistanceCanvas = findClosestDistanceInGroup(group);
        return this.lastScale / (closestDistanceCanvas / PinsRenderer.pinDiameterGroup);
    }
    getPinGroup(groupIndex) {
        return this.pinGroups[groupIndex];
    }
    forceRegenerateActivePinLabel() {
        this.renderActivePinLabelImage(this.activePinSceneKey || '');
    }
    forceRegenerateAccentedPinImages(accentColor) {
        this.renderAccentedPinImages(accentColor, true);
    }
    /**
     * Called when new floor plan is set, not waiting for normal frame render, to avoid rare flicker when
     * the active pin is drawn and then group is drawn over it
     */
    onFloorPlanImageLoaded(scale) {
        this.lastScale = scale; // needed for grouping
        this.groupPins();
    }
    onResize() {
        this.devicePixelRatio = window.devicePixelRatio || 1;
    }
    drawPins(type) {
        if (type === 'hovered-only' && this.hoveredPinGroupIndex === -1)
            return; // nothing to draw
        for (let i = 0; i < this.pinGroups.length; i += 1) {
            const group = this.pinGroups[i];
            if (group.culled)
                continue;
            const groupIsHovered = this.hoveredPinGroupIndex === i;
            // filter
            if (groupIsHovered && type === 'non-hovered-only')
                continue;
            if (!groupIsHovered && type === 'hovered-only')
                continue;
            const groupIsActive = this.activePinGroupIndex === i;
            // if all pins in group are visited, then group is visited
            const groupIsVisited = group.pins.every((pin) => CanvasMiniMapRenderer.visitedScenes.has(pin.key));
            if (!groupIsActive && type === 'active-group-only')
                continue;
            if (groupIsActive && group.pins.length === 1 && type === 'non-hovered-only')
                continue;
            const iconSize = Math.min(3, group.pins.length);
            let ico = PinsRenderer.getRenderedSvg('pinBase', iconSize);
            if (groupIsVisited)
                ico = PinsRenderer.getRenderedSvg('pinVisited', iconSize);
            if (groupIsHovered)
                ico = PinsRenderer.getRenderedSvg('pinFocus', iconSize);
            if (groupIsActive)
                ico = PinsRenderer.getRenderedSvg('pinAccented', iconSize);
            drawImage(this.ctx, ico, group.posCanvas);
        }
    }
    drawActivePin() {
        let canvasPosActivePin;
        // static active pin
        if (this.activePinSceneKey !== null) {
            const group = this.pinGroups[this.activePinGroupIndex];
            if (!group)
                return;
            const canvasPos = group.posCanvas;
            // draw active pin ico if active pin is in a single-pin-group
            if (group.pins.length === 1) {
                canvasPosActivePin = canvasPos;
            }
        }
        // animated active pin (only if the pins are in different groups)
        if (this.isTransitioning() && this.isTransitioningBetweenDifferentPinGroups()) {
            // active pin animated between two pins using pin group location
            const groupA = this.getPinGroupByPinIndex(this.activePinIndexAnimationA);
            const posA = Object.assign({}, groupA.posCanvas);
            // if group has 2 or 3 pins, then offset the active pin to the side,
            // so it look like the animated pin lands on top of the first pin ico
            if (groupA.pins.length > 1) {
                posA.x -= groupA.iconOffsetPosCanvas[0].x;
            }
            const groupB = this.getPinGroupByPinIndex(this.activePinIndexAnimationB);
            const posB = Object.assign({}, groupB.posCanvas);
            if (groupB.pins.length > 1) {
                posB.x -= groupB.iconOffsetPosCanvas[0].x;
            }
            canvasPosActivePin = {
                // progress has easing already applied to it in Engine animator
                x: posA.x + (posB.x - posA.x) * this.activePinAnimationProgress,
                y: posA.y + (posB.y - posA.y) * this.activePinAnimationProgress,
            };
        }
        if (canvasPosActivePin) {
            const ico = PinsRenderer.getRenderedSvg('pinActive');
            // active pin ico is not centered correctly, offset needed
            drawImage(this.ctx, ico, canvasPosActivePin, { x: 0, y: -3 });
        }
    }
    drawActivePinLabel() {
        if (this.activePinSceneKey === null)
            return;
        const group = this.pinGroups[this.activePinGroupIndex];
        if (!group) {
            return;
        }
        const canvasPos = group.posCanvas;
        const txtImg = PinsRenderer.renderedSvgs.get('activePinText');
        if (txtImg) {
            drawImage(this.ctx, txtImg, canvasPos, { x: 0, y: 41 });
        }
    }
    /**
     * If something has changed, recalculate canvas pixel positions of pins/groups
     *
     * @param yaw
     * @param scale
     * @param floorPlanCenter
     * @private
     * @returns true if something has changed
     */
    tryRecalculatePinPixels(yaw, scale, floorPlanCenter) {
        const cullingXMin = -35;
        const cullingXMax = this.canvas.width / this.devicePixelRatio + 35;
        const cullingYMin = -15;
        const cullingYMax = this.canvas.height / this.devicePixelRatio + 155;
        const halfCanvasSizeDpr = this.canvas.width / 2 / this.devicePixelRatio;
        const forceRenderNextFrame = this.forceRenderNextFrame;
        let needToRedraw = false;
        if (this.forceRenderNextFrame ||
            this.lastYaw !== yaw ||
            this.lastScale !== scale ||
            this.floorPlanCenter.x !== floorPlanCenter.x ||
            this.floorPlanCenter.y !== floorPlanCenter.y) {
            this.forceRenderNextFrame = false;
            this.lastYaw = yaw;
            this.lastScale = scale;
            // can't assign whole obj, messes up the equality check
            // (don't even think about spread operator, we conserve memory in this home)
            this.floorPlanCenter.x = floorPlanCenter.x;
            this.floorPlanCenter.y = floorPlanCenter.y;
            needToRedraw = true;
            // infinite-loop-safe: won't recalculate if scale has not changed much
            // (if recalculating, will call this function next frame)
            this.maybeGroupPins();
            // calculate pin positions
            for (let i = 0; i < this.pins.length; i += 1) {
                const pin = this.pins[i];
                pin.posCanvas = fpPixels2CanvasPixels(halfCanvasSizeDpr, pin.posFloorPlan, yaw, scale, floorPlanCenter);
            }
            // calculate pin group positions
            for (let i = 0; i < this.pinGroups.length; i += 1) {
                const group = this.pinGroups[i];
                group.posCanvas = fpPixels2CanvasPixels(halfCanvasSizeDpr, group.posFloorPlan, yaw, scale, floorPlanCenter);
                group.culled =
                    group.posCanvas.x < cullingXMin ||
                        group.posCanvas.x > cullingXMax ||
                        group.posCanvas.y < cullingYMin ||
                        group.posCanvas.y > cullingYMax;
            }
        }
        if (forceRenderNextFrame) {
            // recheck hover only when forced, regular inputs do not need to trigger it
            this.forceCheckHoveredPinGroup();
        }
        return needToRedraw;
    }
    renderAccentedPinImages(accentColor, forceRender = false) {
        if (PinsRenderer.lastAccentColor !== accentColor) {
            const d1 = PinsRenderer.pinDiameter;
            const d2 = PinsRenderer.pinDiameter + pinMultiOffset;
            const d3 = PinsRenderer.pinDiameter + pinMultiOffset * 2;
            const c = 'white';
            const pinActiveImg = svg2Img(getActivePinSvg(accentColor), 28, 35, () => {
                PinsRenderer.renderedSvgs.set('pinActive', pinActiveImg);
                if (forceRender)
                    this.forceRenderParent();
            });
            const pinAccented1Img = svg2Img(getPinSvg(accentColor, c, 1), d1, d1, () => {
                PinsRenderer.renderedSvgs.set('pinAccented1', pinAccented1Img);
                if (forceRender)
                    this.forceRenderParent();
            });
            const pinAccented2Img = svg2Img(getPinSvg(accentColor, c, 2), d2, d1, () => {
                PinsRenderer.renderedSvgs.set('pinAccented2', pinAccented2Img);
                if (forceRender)
                    this.forceRenderParent();
            });
            const pinAccented3Img = svg2Img(getPinSvg(accentColor, c, 3), d3, d1, () => {
                PinsRenderer.renderedSvgs.set('pinAccented3', pinAccented3Img);
                if (forceRender)
                    this.forceRenderParent();
            });
            PinsRenderer.lastAccentColor = accentColor;
        }
    }
    renderActivePinLabelImage(toSceneKey) {
        PinsRenderer.renderedSvgs.delete('activePinText');
        const { roomLabel, fullArea, livingAreaPerc, areaUnit } = this.getNextActivePinLabel(toSceneKey);
        if (!roomLabel && !fullArea)
            return;
        // async because we need to wait for font fetching for 1st render
        getActivePinTextSvg(this.assetPath, areaUnit, roomLabel, fullArea, livingAreaPerc).then((result) => {
            const txtImg = svg2Img(result.svg, result.width, result.height, () => {
                PinsRenderer.renderedSvgs.set('activePinText', txtImg);
                this.forceRenderParent();
            });
        });
    }
    maybeGroupPins() {
        // instantly group if pins have not been grouped yet
        if (this.pinsGroupedAtScale === 9999) {
            this.groupPins();
            return;
        }
        // skip if scale has not changed much
        const deltaScale = Math.abs(this.pinsGroupedAtScale - this.lastScale);
        const minDeltaScale = 0.005;
        if (deltaScale < minDeltaScale)
            return;
        this.debouncedGroupPins();
    }
    /**
     * recalculate hovered pin group to avoid phantom hovers using last known mouse position
     */
    forceCheckHoveredPinGroup() {
        this.inputMouseMove(this.lastMousePos);
    }
    groupPins() {
        this.pinGroups = groupPins(this.pins, this.lastScale, PinsRenderer.pinDiameterGroup);
        this.activePinGroupIndex = -1; // let them calculate anew
        this.pinsGroupedAtScale = this.lastScale;
        // when the timeout comes, we need to force render parent that will also render this,
        // otherwise it will be rendered only on next input
        this.forceRenderParent();
    }
    isTransitioning() {
        return this.activePinIndexAnimationA >= 0 && this.activePinIndexAnimationB >= 0;
    }
    isTransitioningBetweenDifferentPinGroups() {
        const groupIndexA = this.pinGroups.findIndex((group) => group.pins.findIndex((pin) => pin.key === this.pins[this.activePinIndexAnimationA].key) !== -1);
        const groupIndexB = this.pinGroups.findIndex((group) => group.pins.findIndex((pin) => pin.key === this.pins[this.activePinIndexAnimationB].key) !== -1);
        return groupIndexA !== groupIndexB;
    }
    getPinGroupByPinIndex(pinIndex) {
        const index = this.pinGroups.findIndex((group) => group.pins.findIndex((pin) => pin.key === this.pins[pinIndex].key) !== -1);
        return this.pinGroups[index];
    }
    recheckActivePinGroupIndex() {
        // need to find and cache latest activePinGroupIndex if some process has invalidated it
        if (this.activePinSceneKey !== null && this.activePinGroupIndex === -1) {
            this.activePinGroupIndex = this.pinGroups.findIndex((group) => group.pins.findIndex((pin) => pin.key === this.activePinSceneKey) !== -1);
        }
    }
}
/**  canvas pixels */
PinsRenderer.pinDiameter = 26;
/**  canvas pixels, for hovering purposes */
PinsRenderer.pinDiameterHover = 28;
/**  canvas pixels, for grouping purposes (when pins touch within this distance, they are grouped;
 * groups touching is not a thing, only pins are calculated,
 * so bigger than pin diameter is a good value to make sure groups are not touching  ) */
PinsRenderer.pinDiameterGroup = 28;
/** Debounce time: if less than a frame, then it will recalc every frame, if more,
 * then it will wait for the zoom animation to end + debounce delay */
PinsRenderer.pinGroupDebounceTime = 25;
PinsRenderer.renderedSvgs = new Map();
PinsRenderer.lastAccentColor = '';
