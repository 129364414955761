/* eslint-disable class-methods-use-this */
import type {
  AnyHotSpotConfig,
  ClipSpace,
  Degree,
  EasingTypes,
  EngineEventMap,
  HotSpotConfig,
  Pixel,
  Pos,
  Radian,
  SceneConfig,
  ScenePos,
  Size,
} from '@g360/vt-types';
import type { Analytics } from '@g360/vt-utils';
import { toRad } from '@g360/vt-utils';
import { Mixin } from 'ts-mixer';

import type { TourConfigService } from '../common/services/TourConfigService';
import Utils from '../common/Utils';
import type { EngineState, IController } from '../controllers/types';
import HotSpot3D from '../programs/HotSpotProgram3D/HotSpot3D';
import type { AnalyticsEventDetailsHotSpot } from '../types/analytics';
import type { AnimationTypes, CaptionParams, SelectedHotSpot } from '../types/internal';
import EventEmitter from './EventEmitter';

export default abstract class ControllerMixin extends Mixin(EventEmitter) {
  protected minPitchClamp: number | undefined;
  protected maxPitchClamp: number | undefined;

  public abstract isGVAnimatingCamera: boolean;

  protected abstract yaw: number;
  protected abstract pitch: number;
  protected abstract fov: number;
  protected abstract activeSceneConfig: SceneConfig;
  protected abstract canvas: HTMLCanvasElement;
  protected abstract tourConfigService: TourConfigService;
  protected abstract boundingRect: DOMRect;
  protected abstract animatingTransition: boolean;
  protected abstract isSceneMoveAnimated: boolean;
  protected abstract usingNewHotSpots: boolean; // tour version 4+ has new hotspots config
  protected abstract controller: IController;
  protected abstract analytics?: Analytics;
  protected abstract isVerificationNeeded: boolean;

  getYawOffset() {
    return toRad(this.activeSceneConfig.camera[3] || 0);
  }

  getEngineState(): EngineState {
    return {
      yaw: this.yaw,
      pitch: this.pitch,
      fov: this.fov,
      sceneKey: this.activeSceneConfig.sceneKey,
      yawOffset: this.getYawOffset(),
      hotSpots: this.getHotSpots(),
      canvas: this.canvas,
      boundingRect: this.boundingRect,
    };
  }

  initializeController(controller: IController) {
    this.controller = controller;
    this.controller.initialize({
      sceneMoveStart: ({ yaw, pitch, fov }) => {
        const minPitch = this.minPitchClamp ?? this.tourConfigService.minPitch;
        const maxPitch = this.maxPitchClamp;
        const definedFov = fov ?? 0;
        const finalPos = {
          yaw,
          pitch: Utils.clampPitch(pitch, definedFov, this.getCanvasSize(), minPitch, maxPitch),
          fov: Utils.clampFov(definedFov, this.getCanvasSize()),
        };

        this.emit('scene.interaction.start', finalPos);
        this.emit('scene.move.start', finalPos);
      },
      sceneMove: async (
        { yaw, pitch, fov, animate, duration, easing, keepOldAnimation },
        progressCallback?: (progress: number) => void
      ) => {
        if (!keepOldAnimation) this.stopAnimation('look');
        const minPitch = this.minPitchClamp ?? this.tourConfigService.minPitch;
        const maxPitch = this.maxPitchClamp;
        const finalPos = {
          yaw,
          pitch: Utils.clampPitch(pitch, fov, this.getCanvasSize(), minPitch, maxPitch),
          fov: Utils.clampFov(fov, this.getCanvasSize()),
        };
        if (!animate) {
          if (this.yaw !== finalPos.yaw || this.pitch !== finalPos.pitch) {
            this.emit('scene.interaction.update', finalPos);
          }
          this.yaw = finalPos.yaw;
          this.pitch = finalPos.pitch;
          this.fov = finalPos.fov;
          this.render();
        } else if (progressCallback) {
          const animUpdateSubscription = this.subscribe('scene.anim.update', (_currentPos, _targetPos, progress) =>
            progressCallback(progress)
          );
          await this.cameraAnimateTo(finalPos, 'anim', duration, easing);
          animUpdateSubscription.unsubscribe();
        } else {
          await this.cameraAnimateTo(finalPos, 'anim', duration, easing);
        }
        return Promise.resolve(this.getEngineState());
      },
      sceneMoveEnd: ({ yaw, pitch, fov }) => {
        const minPitch = this.minPitchClamp ?? this.tourConfigService.minPitch;
        const maxPitch = this.maxPitchClamp;
        const definedFov = fov ?? 0;

        const finalPos = {
          yaw,
          pitch: Utils.clampPitch(pitch, definedFov, this.getCanvasSize(), minPitch, maxPitch),
          fov: Utils.clampFov(definedFov, this.getCanvasSize()),
        };

        this.emit('scene.interaction.end', finalPos);
      },
      changeScene: async (sceneKey: string, duration?: number) => {
        // if the engine is doing an animation do the wait till it ends then do the transition
        await Promise.all([
          this.animatingTransition ? this.receiveEvent('scene.transition.end') : Promise.resolve(),
          this.isSceneMoveAnimated ? this.receiveEvent('scene.move.end') : Promise.resolve(),
        ]);
        await this.loadSceneKey(sceneKey, duration);
        return this.getEngineState();
      },
      setCaptionText: (textData: CaptionParams, doRender = false) => {
        this.updateCaption(textData, doRender);

        return this.getEngineState();
      },
      hideCaptionText: (doRender = false, noAnimation = false) => {
        this.hideCaption(doRender, noAnimation);

        return this.getEngineState();
      },
      selectHotSpot: (selectedHotSpot: SelectedHotSpot) => {
        this.getHotSpots().forEach((hotSpot, index) => {
          if (selectedHotSpot.index === -1) {
            this.emit('hotspots.info.hover', null);
            this.emit('hotspots.scene.hover', null);
          } else if (index === selectedHotSpot.index) {
            if (hotSpot.type.includes('info')) {
              this.emit('hotspots.info.hover', hotSpot.originalConfig?.id);
            } else {
              this.emit('hotspots.scene.hover', hotSpot.originalConfig?.target);
            }
          }
          // eslint-disable-next-line no-param-reassign
          hotSpot.hover = index === selectedHotSpot.index;
        });
        this.render();
        return Promise.resolve(this.getEngineState());
      },
      clickHotSpot: async (selectedHotSpot: SelectedHotSpot) => {
        const hotSpot = this.getHotSpots()[selectedHotSpot.index];

        if (hotSpot && hotSpot.type.includes('info')) {
          if (hotSpot.type === 'hotspot-info') {
            this.emit('hotspots.info.click', {
              ops: hotSpot.infoContent?.ops,
              clipSpacePos: hotSpot.clipSpacePos as Pos<ClipSpace>,
              config: hotSpot.originalConfig as HotSpotConfig,
              sceneKey: this.activeSceneConfig.sceneKey,
            });
          } else {
            this.updateHotSpot(
              { ...hotSpot.originalConfig, type: 'hotspot-info', disabled: hotSpot.disabled },
              this.activeSceneConfig.sceneKey
            );
          }
          return Promise.resolve(this.getEngineState());
        }

        let hotSpotVersion: string;
        let hotSpotType: string;

        if (hotSpot instanceof HotSpot3D) {
          hotSpotVersion = '3D hotspot';

          if (hotSpot.type === 'doors') hotSpotType = 'Door';
          else if (hotSpot.type === 'stairsUp') hotSpotType = 'Stairs up';
          else if (hotSpot.type === 'stairsDown') hotSpotType = 'Stairs down';
          else if (selectedHotSpot.isArrow) hotSpotType = 'Arrow';
          else hotSpotType = 'Exact';
        } else {
          hotSpotVersion = 'hotspot';

          if (hotSpot.type === 'hotspot-exact') hotSpotType = 'Exact';
          else if (hotSpot.type === 'hotspot-door') hotSpotType = 'Door';
          else if (hotSpot.type === 'hotspot-up') hotSpotType = 'Stairs up';
          else if (hotSpot.type === 'hotspot-down') hotSpotType = 'Stairs down';
          else hotSpotType = 'Arrow';
        }

        this.analytics?.push<AnalyticsEventDetailsHotSpot>('click', 'VT', `${hotSpotType} ${hotSpotVersion}`, {
          curr_scene_id: this.activeSceneConfig.sceneKey,
          next_scene_id: hotSpot.target,
        });

        if (this.isVerificationNeeded) {
          this.emit('scene.transition.gated');
          return Promise.resolve(this.getEngineState());
        }

        try {
          await this.loadSceneKey(hotSpot.target);
        } catch (e) {
          console.warn('transition error', e);
        }

        return Promise.resolve(this.getEngineState());
      },
      moveHotSpot: async (idx: number, updatedPos: ScenePos<Radian>) => {
        if (idx > -1) {
          const newPos: [Degree, Degree] = [Utils.toDeg(updatedPos.pitch), Utils.toDeg(updatedPos.yaw)];
          const { originalConfig, disabled } = this.getHotSpots()[idx];
          const updatedConfig: HotSpotConfig = {
            ...originalConfig,
            disabled,
            pos: newPos,
          };
          this.updateHotSpot(updatedConfig, this.activeSceneConfig.sceneKey, idx);
        }
        return Promise.resolve(this.getEngineState());
      },
      emptyClickCallback: async () => {
        // Deselect selected hotspots if any
        this.getHotSpots().forEach((hotSpot) => {
          if (hotSpot.type.includes('info')) {
            this.updateHotSpot(
              { ...hotSpot.originalConfig, type: 'hotspot-info', disabled: hotSpot.disabled },
              this.activeSceneConfig.sceneKey
            );
          }
        });
        this.render();
        return Promise.resolve(this.getEngineState());
      },
      hotSpotMoveEnd: (idx: number) => {
        const hotSpot = this.getHotSpots()[idx];
        if (hotSpot) {
          this.emit('hotspots.info.release', {
            ops: hotSpot.infoContent?.ops,
            clipSpacePos: hotSpot.clipSpacePos as Pos<ClipSpace>,
            config: { ...hotSpot.originalConfig, type: 'hotspot-info' } as HotSpotConfig,
            sceneKey: this.activeSceneConfig.sceneKey,
          });
        }
        return Promise.resolve(this.getEngineState());
      },
      getEngineState: this.getEngineState.bind(this),
      updateHotSpot: async (hotSpot: any, sceneKey: string) => {
        this.updateHotSpot(hotSpot, sceneKey);
        this.render();
        return Promise.resolve(this.getEngineState());
      },
      zoomPano: (delta: Degree, noAnimation = false) => {
        this.onZoomPano(delta, noAnimation);
        return Promise.resolve(this.getEngineState());
      },
      zoomFloorPlan: (delta: Degree, noAnimation = false) => {
        this.onZoomFloorPlan(delta, noAnimation);
        return Promise.resolve(this.getEngineState());
      },
      preloadScenes: (list: string[]) => {
        this.preloadScenes(list);
      },
      startAnimation: (
        type: AnimationTypes,
        tickCallback: (progress: number) => void,
        finishedCallback: () => void,
        easing?: EasingTypes,
        duration?: number,
        fixedTimeStep?: number
      ) => {
        this.startAnimation(type, tickCallback, finishedCallback, easing, duration, fixedTimeStep);
      },
    });
  }

  receiveEvent(type: keyof EngineEventMap) {
    return new Promise((resolve) => {
      this.subscribe(type, () => resolve(true));
    });
  }

  // if we want to change controller for example from GestureController to RemoteViewingController
  switchController(newController: IController) {
    this.isGVAnimatingCamera = false;
    this.controller.disconnect();
    this.initializeController(newController);
    this.controller.connect();
  }

  public abstract startAnimation(
    type: AnimationTypes,
    tickCallback: (progress: number) => void,
    finishedCallback: () => void,
    easing?: EasingTypes,
    duration?: number,
    fixedTimeStep?: number
  ): void;
  protected abstract getHotSpots(): any[];
  protected abstract render();
  protected abstract getCanvasSize(): Size<Pixel>;
  protected abstract onZoomPano(zoomDelta: number, pinch?: boolean): void;
  protected abstract onZoomFloorPlan(zoomDelta: number, pinch?: boolean): void;
  protected abstract loadSceneKey(sceneKey: string, duration?: number): Promise<void>;
  protected abstract cameraAnimateTo(
    targetPos: ScenePos<Radian>,
    animType?: string,
    duration?: number,
    easing?: EasingTypes
  ): Promise<any>;
  protected abstract stopAnimation(type: AnimationTypes): void;
  protected abstract updateHotSpot(hotSpot: AnyHotSpotConfig, sceneKey: string, index?: number): void;
  protected abstract updateCaption(textData: CaptionParams, doRender: boolean): void;
  protected abstract hideCaption(doRender?: boolean, noAnimation?: boolean): void;
  protected abstract preloadScenes(list: string[]): void;
}
