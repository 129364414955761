import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Utils } from '@g360/vt-engine';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Label from '../../../common/Label';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import { isNumber } from '../common/utils';
const formatArea = (area, units, t) => {
    let stringArea = units === 'imperial' ? Utils.metricToImperialStr(area) : area.toFixed(2);
    if (stringArea.includes('.')) {
        stringArea = stringArea.replace(/\.?0+$/, '');
    }
    return (_jsxs(_Fragment, { children: [`${stringArea} ${units === 'imperial' ? t('units.ft') : t('units.m')}`, _jsx("sup", { children: "2" })] }));
};
const formatPrice = (priceConfig, locale = 'lv-LV') => {
    if ((priceConfig === null || priceConfig === void 0 ? void 0 : priceConfig.value) === undefined || !(priceConfig === null || priceConfig === void 0 ? void 0 : priceConfig.currency))
        return null;
    if (Number.isNaN(Number(priceConfig.value)))
        return null;
    if (Intl === null || Intl === void 0 ? void 0 : Intl.NumberFormat) {
        try {
            const formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: priceConfig.currency,
                minimumFractionDigits: 0,
            });
            return formatter.format(priceConfig.value);
        }
        catch (e) {
            // TODO: Send to sentry instead of logging to console
            // eslint-disable-next-line no-console
            console.warn(e.message || 'Failed to format price');
        }
    }
    return `${priceConfig.currency} ${priceConfig.value}`;
};
const formatAddress = (projectAddress, join) => {
    if (!projectAddress)
        return null;
    if (typeof projectAddress === 'string')
        return projectAddress;
    // NOTE: taken from dashboard
    const { address, city, state, country, postcode, address_optional: addressOptional } = projectAddress;
    const displayAddress = [address, addressOptional, city, state, country, postcode]
        .filter((value) => !!value)
        .join(join);
    return displayAddress;
};
const getRoomName = (roomType, t) => {
    switch (roomType) {
        case '1':
            return { name: t('property-info.bathrooms'), key: 'bathroom-count' };
        case '2':
            return { name: t('property-info.bedrooms'), key: 'bedroom-count' };
        default:
            return null;
    }
};
const PropertyInfo = () => {
    const { tourConfigService } = useServices();
    const { projectDataConfig, units, lsfRenderingMode, showTotalAreas } = useReactive(tourConfigService, [
        'projectDataConfig',
        'units',
        'tourConfig',
        'lsfRenderingMode',
        'showTotalAreas',
    ]);
    const [language, setLanguage] = useState('en');
    // Store the computed width of each label to later calculate the max width
    const [labelWidths, setLabelWidths] = useState({});
    const labelRefCallback = useCallback((ref, labelName) => {
        if (!ref)
            return;
        setLabelWidths((prev) => {
            // if the ref is already set to the correct width, don't update state
            if (prev[labelName] === ref.clientWidth)
                return prev;
            return Object.assign(Object.assign({}, prev), { [labelName]: ref.clientWidth });
        });
    }, []);
    const price = lsfRenderingMode === 'off' ? formatPrice(projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.price, language) : null;
    const totalArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalArea;
    const totalSeparateArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalSeparateArea;
    const reducedHeadroomArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.reducedHeadroomArea;
    const fpStandard = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorplanStandard;
    const floorCount = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorCount;
    const roomCounts = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.roomCounts;
    const address = lsfRenderingMode === 'off' ? formatAddress(projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.address, ', ') : null;
    const t = useTranslation();
    const infoSlots = useMemo(() => {
        const list = [];
        const noStandard = fpStandard === undefined;
        const germanStandard = fpStandard === 'german';
        const defaultStandard = fpStandard === 'default';
        const internationalStandard = fpStandard === 'international';
        if (showTotalAreas && isNumber(totalArea)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let labelName = '';
            if (noStandard)
                labelName = t('property-info.total-area');
            else if (defaultStandard || internationalStandard)
                labelName = t('property-info.approx-total-area');
            else if (germanStandard)
                labelName = t('property-info.living-area');
            list.push({
                label: labelName,
                value: formatArea(totalArea, units, t),
                key: 'total-area',
            });
        }
        if (showTotalAreas && isNumber(totalSeparateArea) && (germanStandard || defaultStandard || internationalStandard)) {
            let labelName = '';
            if (germanStandard)
                labelName = t('property-info.usable-area');
            else if (defaultStandard || internationalStandard)
                labelName = t('property-info.balconies-terraces');
            list.push({
                label: labelName,
                value: formatArea(totalSeparateArea, units, t),
                key: 'total-usable-area',
            });
        }
        if (showTotalAreas && isNumber(reducedHeadroomArea) && defaultStandard) {
            list.push({
                label: t('property-info.reduced-headroom'),
                value: formatArea(reducedHeadroomArea, units, t),
                key: 'reduced-headroom-area',
            });
        }
        // Check if floor count is a number
        if (isNumber(floorCount)) {
            list.push({ label: t('property-info.floor-count'), value: `${floorCount}`, key: 'floor-count' });
        }
        if (roomCounts) {
            roomCounts.forEach(({ roomType, count }) => {
                const roomName = getRoomName(roomType, t);
                if (roomName && isNumber(count)) {
                    list.push({ label: roomName.name, value: count, key: roomName.key });
                }
            });
        }
        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDataConfig, units, t]);
    const maxColWidth = useMemo(() => {
        const shownKeys = infoSlots.map(({ label }) => label);
        const shownWidths = Object.entries(labelWidths).filter(([key]) => shownKeys.includes(key));
        return shownWidths.reduce((acc, [, width]) => Math.max(width, acc), 0);
    }, [infoSlots, labelWidths]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setLanguage(window.navigator.language);
        }
    }, []);
    useEffect(() => {
        // @ts-expect-error - dev panel integration
        if (process.env.REACT_APP_DEV_PANEL === 'true' && (window === null || window === void 0 ? void 0 : window.devSettings)) {
            // @ts-expect-error - dev panel integration
            window.devSettings.watch(() => {
                // @ts-expect-error - dev panel integration
                setLanguage(window.devSettings.language);
            });
        }
    }, []);
    const twSlotsMarginTop = !address && !price ? 'mt-0' : 'mt-4';
    return (_jsxs("div", Object.assign({ className: "mb-8 flex flex-col items-start" }, { children: [address && (_jsx(Label, Object.assign({ size: "normal", className: "h-full grow", testId: "address-label" }, { children: address }))), price && (_jsx(Label, Object.assign({ size: "medium", className: "h-full grow", testId: "price-label" }, { children: price }))), infoSlots.length > 0 && (_jsx("div", Object.assign({ className: `grid w-full gap-x-8 gap-y-6 ${twSlotsMarginTop}`, style: { gridTemplateColumns: `repeat(auto-fit, minmax(auto, ${maxColWidth}px))` } }, { children: infoSlots.map(({ label, value, key }) => (_jsxs("div", Object.assign({ className: "w-max", ref: (ref) => labelRefCallback(ref, label) }, { children: [_jsx(Label, Object.assign({ size: "small", selectable: false }, { children: label })), _jsx(Label, Object.assign({ size: "medium", testId: `${key}-value` }, { children: value }))] }), label))) })))] })));
};
export default PropertyInfo;
