var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
const Input = forwardRef((_a, ref) => {
    var { className = '', errorClassName = '', containerClassName = '', label = '', error, hideErrorMessage, testId } = _a, rest = __rest(_a, ["className", "errorClassName", "containerClassName", "label", "error", "hideErrorMessage", "testId"]);
    const twBorder = error ? 'border-modal-red' : 'border-modal-gray-light focus:border-modal-gray-dark';
    return (_jsxs("div", Object.assign({ className: `relative w-full ${containerClassName}` }, { children: [_jsx("input", Object.assign({}, rest, { ref: ref, id: "floating-label-input", className: `
            peer px-6 pb-2 pt-6 font-primary text-base font-normal
            text-modal-dark outline-none no-appearance ${twBorder} ${className}`, placeholder: " ", "data-testid": testId })), _jsx("label", Object.assign({ htmlFor: "floating-label-input", className: `
            peer-focus:text-sx pointer-events-none absolute left-6 top-2 flex h-4 transform items-center
            font-primary text-xs font-bold text-modal-gray-label duration-300
            peer-placeholder-shown:top-4 peer-placeholder-shown:h-6 peer-placeholder-shown:text-base peer-focus:top-2
            peer-focus:h-4 peer-focus:text-xs
          ` }, { children: label })), error && !hideErrorMessage && (_jsx("label", Object.assign({ htmlFor: "floating-label-input", className: `items-center pl-6 pt-1 font-primary text-xs font-bold text-modal-red ${errorClassName}` }, { children: error })))] })));
});
Input.displayName = 'Input';
export default Input;
