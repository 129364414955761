import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import Scrollbars from '../layout/components/Scrollbars';
const PanoSwitcher = ({ className }) => {
    const { gatedTourService, navigationService } = useServices();
    const { activeScene, activeSubScene, transitionInProgress } = useReactive(navigationService, [
        'activeScene',
        'activeSubScene',
        'transitionInProgress',
    ]);
    const subScenes = useMemo(() => {
        var _a;
        return Object.keys((_a = activeScene.subScenes) !== null && _a !== void 0 ? _a : {}).map((k, i) => ({
            sceneKey: k,
            subSceneIdx: i,
        }));
    }, [activeScene]);
    const subSceneIndex = useMemo(() => subScenes.findIndex((subScene) => subScene.sceneKey === (activeSubScene === null || activeSubScene === void 0 ? void 0 : activeSubScene.sceneKey)), [activeSubScene, subScenes]);
    const otherScenes = useMemo(() => {
        const visibleScene = activeSubScene !== null && activeSubScene !== void 0 ? activeSubScene : activeScene;
        return [{ sceneKey: activeScene.sceneKey, subSceneIdx: null, displayedIdx: 1 }, ...subScenes].filter((scene) => scene.sceneKey !== visibleScene.sceneKey);
    }, [activeScene, subScenes, activeSubScene]);
    if (subScenes.length === 0)
        return null;
    if (subScenes.length <= 2) {
        return (_jsxs("div", Object.assign({ className: `flex rounded-lg bg-theme-primary bg-opacity-theme-low p-0.5 ${className !== null && className !== void 0 ? className : ''}` }, { children: [_jsx("button", Object.assign({ type: "button", className: `h-9 w-9 rounded-md ${subSceneIndex === -1 ? 'btn-theme-active' : 'btn-theme-primary bg-opacity-0'} ${transitionInProgress ? 'cursor-not-allowed' : 'cursor-pointer'}`, onClick: () => {
                        if (!gatedTourService.checkVerification())
                            return;
                        navigationService.switchSubScene(activeScene.sceneKey, activeScene.sceneKey);
                    }, "data-testid": "alt-pano-btn" }, { children: "1" })), subScenes.map((subScene) => (_jsx("button", Object.assign({ type: "button", className: `h-9 w-9 rounded-md ${subSceneIndex === subScene.subSceneIdx ? 'btn-theme-active' : 'btn-theme-primary bg-opacity-0'} ${transitionInProgress ? 'cursor-not-allowed' : 'cursor-pointer'}`, onClick: () => {
                        if (!gatedTourService.checkVerification())
                            return;
                        navigationService.switchSubScene(activeScene.sceneKey, subScene.sceneKey);
                    }, "data-testid": "alt-pano-btn" }, { children: subScene.subSceneIdx === null ? 1 : subScene.subSceneIdx + 2 }), subScene.sceneKey)))] })));
    }
    return (_jsxs(Listbox, Object.assign({ as: "div", disabled: transitionInProgress, className: className, value: subSceneIndex, onChange: (newIndex) => {
            if (!gatedTourService.checkVerification())
                return;
            if (newIndex === null) {
                navigationService.switchSubScene(activeScene.sceneKey, activeScene.sceneKey);
                return;
            }
            navigationService.switchSubScene(activeScene.sceneKey, subScenes[newIndex].sceneKey);
        } }, { children: [_jsx(Listbox.Button, Object.assign({ as: "button", className: "btn-theme-primary group pointer-events-auto h-10 w-16 min-w-0 disabled:cursor-not-allowed disabled:hover:bg-opacity-theme-low", "data-testid": "pano-switch-btn" }, { children: subSceneIndex === -1 ? 1 : subSceneIndex + 2 })), _jsx(Transition, Object.assign({ as: Fragment }, { children: _jsx(Listbox.Options, Object.assign({ className: "mt-2.5 flex w-16 flex-col rounded-lg" }, { children: _jsx(Scrollbars, Object.assign({ className: "max-h-[15.25rem]" }, { children: otherScenes.map((scene) => (_jsx(Listbox.Option, Object.assign({ value: scene.subSceneIdx, className: "btn-theme-secondary group h-10 min-h-[2.5rem] w-full cursor-pointer", "data-testid": "alt-pano-btn" }, { children: scene.subSceneIdx === null ? 1 : scene.subSceneIdx + 2 }), scene.sceneKey))) })) })) }))] })));
};
export default PanoSwitcher;
