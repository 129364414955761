import type {
  AnyHotSpotConfig,
  AppContext,
  AssetConfig,
  BlurMaskData,
  Degree,
  EasingTypes,
  EngineConfig,
  EngineProps,
  GuidedViewingConfig,
  HotSpotConfig,
  MeasureModePlatform,
  Milliseconds,
  NavigationMode,
  Pixel,
  Radian,
  SceneConfig,
  ScenePos,
  Size,
  Subscription,
  SyncData,
  TourConfig,
  TourEventSource,
} from '@g360/vt-types';
import type { Analytics } from '@g360/vt-utils';
import { linearScale } from '@g360/vt-utils/';
import isEqual from 'lodash/isEqual';
import { Mixin } from 'ts-mixer';

import ContrastChecker from './common/ContrastChecker';
import {
  DEFAULT_ASSET_CONFIG,
  DEFAULT_ENGINE_CONFIG,
  DEFAULT_FOV_DEG,
  MAX_FOV_RAD,
  MODE_SWITCH_WAIT_TIME,
  MODE_SWITCH_WAIT_TIMEOUT,
} from './common/Globals';
import RendererDebug from './common/RendererDebug';
import ScenePreloader from './common/ScenePreloader';
import type { TourConfigService } from './common/services/TourConfigService';
import Utils, { clamp } from './common/Utils';
import GestureController from './controllers/GestureController';
import GuestController from './controllers/GuidedViewing/GuestController';
import MeasureToolController from './controllers/MeasureTool/MeasureToolController';
import type { IController } from './controllers/types';
import DebugControls from './DebugControls';
import Animator from './mixins/Animator';
import Camera from './mixins/Camera';
import ControllerMixin from './mixins/ControllerMixin';
import EventEmitter from './mixins/EventEmitter';
import GuidedViewing from './mixins/GuidedViewing';
import Renderer from './mixins/Renderer';
import type BlurProgram from './programs/BlurProgram';
import type HotSpot from './programs/HotSpotProgram/HotSpot';
import type VideoCaptionProgram from './programs/VideoCaptionProgram';
import type { MeasureToolDebugParams } from './types/internal';

class Engine extends Mixin(Renderer, EventEmitter, Animator, GuidedViewing, Camera, ControllerMixin) {
  public watermarkInterrupted = false;
  public animatingTransition = false;
  public sceneTransition = false;
  public overlay = false;
  /** Is preview or equirect layout loaded and ready to render */
  public isSceneLayoutReady = false;
  protected assetConfig: AssetConfig;
  protected engineConfig: EngineConfig;
  protected _fov = Utils.toRad(DEFAULT_FOV_DEG);
  protected _pitch: Radian = 0;
  protected _yaw: Radian = 0;
  protected _zoomFloorPlan = 0;
  protected activeHotSpot: HotSpot | null = null;
  protected activeSceneConfig: SceneConfig;
  protected prevSceneConfig?: SceneConfig;
  protected canvas: HTMLCanvasElement;
  protected contrastChecker = new ContrastChecker('#231720');
  protected isEngineReady = false;
  protected controller: IController;
  /** 0..1 fov percentage from currently allowed min..max */
  protected zoomRatio = 0;
  protected tourEventSource: TourEventSource = 'DOMEvents';
  protected isSceneMoveAnimated = false;
  protected usingNewHotSpots: boolean;
  protected debugControls?: DebugControls;
  protected guidedViewingConfig: GuidedViewingConfig;
  protected boundingRect: DOMRect;
  protected hasInfoHotSpots = false;
  protected tourConfigService: TourConfigService;
  protected appContext: AppContext = 'standalone';
  protected analytics?: Analytics;
  protected minPitchClamp: number | undefined;
  protected maxPitchClamp: number | undefined;
  protected isVerificationNeeded = false;
  private readonly scenePreloader?: ScenePreloader;
  private modeSwitchInitiatedTime: Milliseconds = 0;
  /** corresponds to zoom direction */
  private modeSwitchDirection: 'up' | 'down' | null = null;

  /** Store UI configuration before entering measure mode, to
   * have the possibility to restore it after exiting measure mode
   */
  private preMeasureModeConfig: {
    navigationMode: NavigationMode;
    renderHotSpots: boolean;
  } | null = null;

  /** Stores the last visited subScene sceneKey of the corresponding main scene */
  private lastVisitedSubScenes: { [mainSceneKey: string]: string } = {};

  constructor({
    canvas,
    tourConfigService,
    assetConfig = DEFAULT_ASSET_CONFIG,
    engineConfig = DEFAULT_ENGINE_CONFIG,
    controller = new GestureController(),
    analytics,
  }: EngineProps<TourConfigService, IController, Analytics>) {
    super();
    if (!tourConfigService) throw new Error('tourConfig or configService is required');

    this.tourConfigService = tourConfigService;
    this.setAnalytics(analytics);
    if (__DEV_PANEL__) {
      this.debugControls = new DebugControls(this.actualCameraPos);
    }

    this.canvas = canvas;

    this.guidedViewingConfig = {
      enabled: false,
      isInControlOfTour: true,
      onHostEmit: () => undefined,
      onGuestReceive: () => undefined,
    };

    this.resizeRenderingBuffer();

    this.oldGeometry = this.tourConfigService.configStructureType === 'layers'; // used for workaround in DepthProgram for transition
    this.usingNewHotSpots = this.tourConfigService.getVersion() >= 4;

    this.hasInfoHotSpots = Object.values(this.tourConfigService.scenes).some((scene) =>
      scene.hotSpots?.some((hotSpot) => hotSpot.type === 'hotspot-info')
    );

    this.assetConfig = { ...DEFAULT_ASSET_CONFIG, ...assetConfig };
    this.engineConfig = { ...DEFAULT_ENGINE_CONFIG, ...engineConfig };

    // -------------------------------------------------------------------------
    // NEW HOTSPOTS
    // -------------------------------------------------------------------------
    // @todo -- rem, only for debug
    // -------------------------------------------------------------------------
    if (RendererDebug.getDebugStringParam('hotspotDataEncoded', '')) {
      this.usingNewHotSpots = true; // if new hotspot data found in URl, then use new hotspots

      // overwrite pano yaw offset (camera[3]) with values from new hotspot data
      const json = RendererDebug.tryGetHotSpotDataFromUrlParamSync();
      if (this.tourConfigService.tourConfig && json) {
        Object.values(this.tourConfigService.scenes).forEach((scene) => {
          if (json.scenes[scene.sceneKey].camera && this.tourConfigService.scenes[scene.sceneKey].camera) {
            // const og = this.tourConfigService.scenes[scene.sceneKey].camera;
            this.tourConfigService.scenes[scene.sceneKey].camera[3] = json.scenes[scene.sceneKey].camera[3];
            // console.log(`set scene rotation to ${Math.round(json.scenes[scene.sceneKey].camera[3])}° for scene ${scene.sceneKey}`,   `from some json we found in the URL. (og=${og})`  );
          }
        });
      }
    }

    this.blurEditorEnabled = engineConfig.blurMode;
    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();
    this.boundingRect = this.getCanvasBoundingClientRect();
    this.controller = controller;

    if (__USE_VIDEO_EDITOR__) {
      this.scenePreloader = new ScenePreloader(this.tourConfigService, this.assetConfig, this);
    }

    this.getActiveSceneKey = this.getActiveSceneKey.bind(this);
    this.getCanvasSize = this.getCanvasSize.bind(this);
  }

  // public API

  /** Initialize engine/rendering and start loading assets */
  public start() {
    this.initRenderer(this.debugControls);
    this.loadSceneConfig(this.activeSceneConfig);
    this.loadInitialView(this.activeSceneConfig);
    this.initializeController(this.controller);
    this.controller.connect();

    // watch the TourConfigService for changes
    this.watchConfigChanges();
  }

  public getTourConfigService() {
    return this.tourConfigService;
  }

  public emitInfoHotSpotRelease() {
    this.emit('hotspots.info.release', null);
  }

  getTourEventSource(): TourEventSource {
    return this.tourEventSource;
  }

  geActualCameraPos(inverse = true): number[] {
    if (inverse) return this.actualCameraPos.map((pos) => Math.round(-pos));
    return this.actualCameraPos;
  }

  destroy(): void {
    this.controller.disconnect();
    this.destroyRenderer();
    this.destroyEventEmitter();
  }

  public loadScene({
    sceneKey,
    pitch,
    yaw,
  }: {
    /** sceneKey in case of main scene, [mainSceneKey, subSceneKey] in case of subScene */
    sceneKey: string | [string, string];
    pitch: Degree | undefined;
    yaw: Degree | undefined;
  }): void {
    if (!Array.isArray(sceneKey) && (!this.activeSceneConfig || sceneKey !== this.activeSceneConfig.sceneKey)) {
      this.activeSceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);

      this.loadSceneConfig(this.activeSceneConfig);
    } else if (Array.isArray(sceneKey)) {
      const mainScene = this.tourConfigService.getSceneConfigByKey(sceneKey[0]);
      const subScene = this.tourConfigService.getSceneConfigByKey(sceneKey);

      this.loadSubSceneConfig(mainScene, subScene);
    }

    if (yaw !== undefined) {
      this.yaw = yaw ? Utils.toRad(yaw) : 0;
    }
    if (pitch !== undefined) {
      this.pitch = pitch ? Utils.toRad(pitch) : 0;
    }

    this.render();
  }

  /**
   * Load new {@link TourConfig} and set basePath.
   * Will use already set custom branding settings as a priority. Use {@link resetBrandingSettings} to remove them
   * and load all the settings from provided tourConfig
   * */
  loadTourConfig(tourConfig: TourConfig, assetConfig: AssetConfig = DEFAULT_ASSET_CONFIG): void {
    this.fov = Utils.toRad(DEFAULT_FOV_DEG);
    this.pitch = 0;
    this.yaw = 0;

    this.tourConfigService.reload(tourConfig);
    this.assetConfig = assetConfig;

    this.destroyRenderer();
    this.initRenderer(this.debugControls);

    if (this.guidedViewingConfig.isInControlOfTour) {
      this.switchController(new GestureController());
    } else {
      this.switchController(new GuestController());
    }

    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();

    this.previousSceneConfig = undefined;
    this.loadRendererConfig(this.activeSceneConfig);
    this.loadInitialView(this.activeSceneConfig);
  }

  getController(): IController {
    return this.controller;
  }

  getAssetConfig(): AssetConfig {
    return this.assetConfig;
  }

  /** Gets scene yaw position in radians, this is the internal engine angle, not relative to the layout */
  getYaw(): Radian {
    return this.yaw;
  }

  /** Gets scene pitch position in radians, 0 is at the center of layout image */
  getPitch(): Radian {
    return this.pitch;
  }

  /** Gets scene yaw position in degrees, relative to the layout, use this for initial view */
  getLayoutYawDeg(): Degree {
    return Utils.toDeg(this.yaw) + this.getActiveSceneConfig().camera[3];
  }

  /** Gets scene fov position in radians */
  getFov(): Radian {
    return this.fov || Utils.toRad(DEFAULT_FOV_DEG);
  }

  // eslint-disable-next-line class-methods-use-this
  getMaxFov(): Radian {
    return MAX_FOV_RAD;
  }

  /** Gets the calibration offset from json data for active scene */
  getYawOffset(): Radian {
    return Utils.toRad(this.getActiveSceneConfig().camera[3]);
  }

  /** Get current pitch value with pitch limit applied */
  getClampedPitch(): Radian {
    const min = this.minPitchClamp ?? this.tourConfigService.minPitch;
    const max = this.maxPitchClamp;
    return Utils.clampPitch(this.pitch, this.fov, this.getCanvasSize(), min, max);
  }

  /** Gets currently active scene config */
  getActiveSceneConfig(): SceneConfig {
    return this.activeSceneConfig;
  }

  getActiveSceneKey(): string {
    return this.activeSceneConfig.sceneKey || this.tourConfigService.getFirstSceneKey();
  }

  /** Loads scene by provided scene config object, except if transition already is in progress */
  async loadSceneConfig(sceneConfig: SceneConfig, duration?: number) {
    // If the target scene has been previously visited, load the last visited subScene
    // of that scene group
    const lastVisitedSubSceneKey = this.lastVisitedSubScenes[sceneConfig.sceneKey];
    const lastVisitedSubScene = lastVisitedSubSceneKey
      ? this.tourConfigService.scenes[sceneConfig.sceneKey].subScenes?.[lastVisitedSubSceneKey]
      : undefined;

    if (lastVisitedSubScene) {
      this.loadSubSceneConfig(sceneConfig, lastVisitedSubScene, duration);
      return;
    }

    // TODO: not sure why this was necessary for GV, but in standalone player and editors this is annoying
    if (this.isInGuidedViewing() && this.isSceneMoveAnimated) return;
    if (this.animatingTransition) return;

    if (this.isSceneMoveAnimated) this.stopAnimation('look');

    if (sceneConfig) {
      this.prevSceneConfig = this.activeSceneConfig;
      this.activeSceneConfig = sceneConfig;

      await this.loadRendererConfig(sceneConfig, duration);
    } else {
      throw new Error('Invalid scene config!');
    }
  }

  /** Loads scene by provided scene config object, except if transition already is in progress */
  async loadSubSceneConfig(mainSceneConfig: SceneConfig, subSceneConfig: SceneConfig, duration?: number) {
    // TODO: not sure why this was necessary for GV, but in standalone player and editors this is annoying
    if (this.isInGuidedViewing() && this.isSceneMoveAnimated) return;
    if (this.animatingTransition) return;

    if (this.isSceneMoveAnimated) this.stopAnimation('look');

    if (mainSceneConfig && subSceneConfig) {
      if (mainSceneConfig !== this.activeSceneConfig) {
        this.prevSceneConfig = this.activeSceneConfig;
        this.activeSceneConfig = mainSceneConfig;
      }

      await this.loadRendererConfig([mainSceneConfig, subSceneConfig], duration);
      this.lastVisitedSubScenes[mainSceneConfig.sceneKey] = subSceneConfig.sceneKey;
    } else {
      throw new Error('Invalid scene config!');
    }
  }

  public addHotSpot(newHotSpotConfig: AnyHotSpotConfig, sceneKey: string) {
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);

    if (sceneConfig.hotSpots) {
      sceneConfig.hotSpots.push(newHotSpotConfig);
    } else {
      sceneConfig.hotSpots = [newHotSpotConfig];
    }

    this.updateHotSpotProgram(newHotSpotConfig, sceneKey, 'add');
  }

  public updateHotSpot(updatedHotSpotConfig: AnyHotSpotConfig, sceneKey: string) {
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);

    if (!sceneConfig.hotSpots) return;

    sceneConfig.hotSpots = sceneConfig.hotSpots.map((hotSpotConfig) => {
      if (hotSpotConfig.id === updatedHotSpotConfig.id) {
        return updatedHotSpotConfig;
      }

      return hotSpotConfig;
    });

    this.updateHotSpotProgram(updatedHotSpotConfig, sceneKey, 'update');
  }

  public deleteHotSpot(deletedHotSpotConfig: AnyHotSpotConfig, sceneKey: string) {
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);

    if (!sceneConfig.hotSpots) return;

    this.tourConfigService.scenes[sceneKey].hotSpots = sceneConfig.hotSpots.filter(
      (hotSpotConfig) => hotSpotConfig.id !== deletedHotSpotConfig.id
    );

    this.updateHotSpotProgram(deletedHotSpotConfig, sceneKey, 'delete');

    if (deletedHotSpotConfig.id === this.activeHotSpot?.originalConfig.id) {
      this.activeHotSpot = null;
    }

    this.emit('hotspots.info.delete', deletedHotSpotConfig as HotSpotConfig);
  }

  /** @todo check the usage of the function probably we don't need it in favour of switchController */
  toggleCanvasEvents(active: boolean) {
    if (active && this.guidedViewingConfig.isInControlOfTour) {
      this.controller.connect();
    } else {
      this.controller.disconnect();
    }
  }

  /**
   * Loads scene by scene key, will fall back to the first valid scene if key is invalid, see {@link getFirstSceneKey}
   */
  loadSceneKey(sceneKey: string, duration?: number): Promise<void> {
    if (this.activeSceneConfig.sceneKey === sceneKey) return Promise.resolve();

    const sceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);
    return this.loadSceneConfig(sceneConfig, duration);
  }

  /** Check if engine is ready to accept API calls */
  isReady(): boolean {
    return this.isEngineReady;
  }

  /** Is scene layout (preview or equirect) loaded and ready to render */
  isSceneReady(): boolean {
    return this.isSceneLayoutReady;
  }

  /** Emit the event when scene gets hovered in the external source - player, editor or wherever */
  emitSceneHoverEvent(sceneKey: string | null) {
    this.emit('hotspots.scene.hover', sceneKey);
  }

  getCanvasBoundingClientRect(): DOMRect {
    return this.canvas.getBoundingClientRect();
  }

  getCanvasSize(): Size<Pixel> {
    return { width: this.canvas.clientWidth, height: this.canvas.clientHeight };
  }

  getCanvasElement(): HTMLCanvasElement {
    return this.canvas;
  }

  setBlurMasks(sceneKey: string, intensity: number, masks: BlurMaskData[], highQuality: boolean): void {
    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
    if (blurProgram) {
      blurProgram.setBlurMasksAndRegenerateBlurs(sceneKey, intensity, masks, highQuality);
      this.render();
    }
  }

  getBlurMaskPngDataUrl(sceneKey: string, width: number): Promise<string> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
      if (blurProgram) {
        return blurProgram.getBlurMaskPngDataUrl(sceneKey, width).then((png) => {
          resolve(png);
        });
      }
      reject();
    });
  }

  getBlurMaskPngBlob(sceneKey: string, width: number): Promise<Blob> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
      if (blurProgram) {
        return blurProgram.getBlurMaskPngBlob(sceneKey, width).then((pngBlob) => {
          resolve(pngBlob);
        });
      }
      reject();
    });
  }

  // For blur editor (when Engine is started with blur mode on)
  // is used to turn off blurs while in transition or for any reason in the editor
  // level 0 - for renderer
  // level 1 - for editor
  toggleBlurmaskRendering(on: boolean, level: number): void {
    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
    if (blurProgram) {
      if (level === 0) blurProgram.visibleA = on;
      else blurProgram.visibleB = on;

      this.render();
    }
  }

  // Remote viewing api

  initGuidedViewing(config: GuidedViewingConfig): void {
    this.guidedViewingConfig = { ...config };
    this.startGuidedViewingObservers();
    this.setTourEventSource(config.isInControlOfTour ? 'DOMEvents' : 'RemoteHost');
    this.guidedViewingConfig.enabled = true;
    this.emit('remoteSyncReady');
  }

  /**
   * Check if guided viewing is enabled and initialized
   * @note Use the remoteSyncReady event to initialize and this to check if
   * already initialized after the subscription so you don't miss the event
   * */
  isInGuidedViewing = () => this.guidedViewingConfig?.enabled;

  toggleGuidedViewingHostState(isInControlOfTour = true): void {
    this.guidedViewingConfig = { ...this.guidedViewingConfig, isInControlOfTour };
    this.setTourEventSource(isInControlOfTour ? 'DOMEvents' : 'RemoteHost');
  }

  /** Animate camera to a specific position */
  cameraAnimateTo(targetPos: ScenePos, animType = 'anim', duration?: number, easing?: EasingTypes) {
    if (this.animatingTransition || this.isSceneMoveAnimated) return Promise.resolve(true);
    if (this.sceneTransition) return Promise.resolve(true);

    const startPos = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };

    if (isEqual(startPos, targetPos)) return Promise.resolve(true);

    return new Promise((resolve) => {
      // TODO(uzars): need to handle types better, possibly use template literal types / unions?
      this.isSceneMoveAnimated = true;

      if (animType === 'ping') {
        this.emit(`scene.ping.start`, { pitch: this.pitch, yaw: this.yaw }, targetPos, 0);
      } else {
        this.emit(`scene.anim.start`, { pitch: this.pitch, yaw: this.yaw }, targetPos, duration ?? 0);
      }

      this.startAnimation(
        'look',
        (progress) => {
          this.pitch = linearScale(progress, [0, 1], [startPos.pitch, targetPos.pitch]);
          this.yaw = linearScale(progress, [0, 1], [startPos.yaw, targetPos.yaw]);
          this.fov = linearScale(progress, [0, 1], [startPos.fov, targetPos.fov || startPos.fov]);

          this.emit(
            animType === 'ping' ? `scene.ping.update` : `scene.anim.update`,
            { pitch: this.pitch, yaw: this.yaw, fov: this.fov },
            targetPos,
            progress * 100
          );
          this.emit('scene.move.update', { pitch: this.pitch, yaw: this.yaw, fov: this.fov });

          this.promiseOneFrame();
        },
        () => {
          this.emit(
            animType === 'ping' ? `scene.ping.end` : `scene.anim.end`,
            { pitch: this.pitch, yaw: this.yaw, fov: this.fov },
            targetPos,
            100
          );
          this.emit('scene.move.end');

          this.promiseOneFrame().then(async () => {
            // This is to trigger the one additional frame render needed to check if the scene is moving
            await this.promiseOneFrame();
            resolve(true);
            this.isSceneMoveAnimated = false;
          });
        },
        easing ?? 'easeOutQuad',
        duration
      );
    });
  }

  /** Change layout image BitMap Options in runtime */
  setEquirectOptions({ primaryOptions, secondaryOptions }) {
    if (this.assetConfig.equirectAssets) {
      this.assetConfig.equirectAssets.primaryOptions = primaryOptions;
      this.assetConfig.equirectAssets.secondaryOptions = secondaryOptions;
      this.updateEquirectOptions();
    }
  }

  public onResize(): void {
    // NOTE(uzars): if stopAnimation is called in recording mode it will cancel the first autoplay animation.
    // For projects with only one animation this will crash the ffmpeg as there are no rendered frames.
    if (!this.engineConfig.recordingMode) this.stopAnimation('look');

    this.fov = linearScale(this.zoomRatio, [1, 0], Utils.getFovBoundary(this.getCanvasSize()));
    this.boundingRect = this.getCanvasBoundingClientRect();
    this.resizeRenderingBuffer();
    this.resizeFramebufferTextures();
    this.clampView();
    this.setOptimalLevel();
  }

  public setMeasureDebugParams(params: MeasureToolDebugParams) {
    this.changeMeasureDebugParams(params);
  }

  public setNewDepthMap(file: File) {
    this.changeDepthMap(file);
  }

  public setNewNormalMap(file: File) {
    this.changeNormalMap(file);
  }

  public setNewEdgeMap(file: File) {
    this.changeEdgeMap(file);
  }

  public async toggleMeasureMode(newState: boolean): Promise<MeasureModePlatform | null> {
    const subscriptions: Subscription[] = [];

    if (newState) {
      // Custom controller to lock camera while dragging measure points
      const newController = new MeasureToolController();
      subscriptions.push(
        this.subscribe('measuretool.camera.lock', () => {
          newController.panDisabled = true;
        })
      );
      subscriptions.push(
        this.subscribe('measuretool.camera.unlock', () => {
          newController.panDisabled = false;
        })
      );
      subscriptions.push(
        this.subscribe('scene.preload.start', () => {
          newController.loading = true;
        })
      );
      subscriptions.push(
        this.subscribe('scene.preload.end', () => {
          newController.loading = false;
        })
      );
      this.switchController(newController);
      this.preMeasureModeConfig = {
        navigationMode: this.tourConfigService.navigationMode,
        renderHotSpots: this.engineConfig.renderHotSpots,
      };
      // Disable minimap
      this.tourConfigService.navigationMode = 'none';
      // Disable hotspots
      this.setHotSpotsDisabled(true);

      return this.toggleMeasureTool(this.tourConfigService.units);
    }
    // Custom controller cleanup
    subscriptions.forEach((s) => s.unsubscribe());
    subscriptions.length = 0;
    this.switchController(new GestureController());
    // Reenable minimap and hotspots
    if (this.preMeasureModeConfig) {
      this.tourConfigService.navigationMode = this.preMeasureModeConfig.navigationMode;
      this.setHotSpotsDisabled(!this.preMeasureModeConfig.renderHotSpots);
    } else {
      this.tourConfigService.navigationMode = 'full';
      this.setHotSpotsDisabled(false);
    }
    this.preMeasureModeConfig = null;

    this.toggleMeasureTool(false);
    return null;
  }

  public getCaptionData() {
    if (!__USE_VIDEO_EDITOR__)
      return {
        boundingBox: null,
      };

    const videoCaptionProgram = this.getProgram<VideoCaptionProgram>('VideoCaptionProgram');

    if (!videoCaptionProgram)
      return {
        boundingBox: null,
      };

    return videoCaptionProgram.getCaptionData();
  }

  /**
   * @returns Set<string> of all preloaded or visited scenes. Undefined if ScenePreloader is not active.
   */
  getPreloadedScenes(): Set<string> | undefined {
    return this.scenePreloader?.getPreloadedScenes();
  }

  public setAppContext(appContext: AppContext) {
    this.appContext = appContext;
  }

  // Force minimap to rerender for debug purposes
  public forceMinimapRerender() {
    this.emit('minimap.rerender.force');
  }

  public setAnalytics(analytics?: Analytics) {
    this.analytics = analytics;

    if (!analytics) return;

    analytics.setGetCurrentSceneIdFunction(this.getActiveSceneKey);
    analytics.setGetCanvasSizeFunction(this.getCanvasSize);

    const { analyticsTimeHelper, analyticsSceneDragHelper, analyticsSceneInertiaHelper, analyticsSceneZoomHelper } =
      analytics;

    analyticsTimeHelper.unsubscribeAll();
    analyticsSceneDragHelper.unsubscribeAll();
    analyticsSceneInertiaHelper.unsubscribeAll();
    analyticsSceneZoomHelper.unsubscribeAll();

    analyticsTimeHelper.addSubscription(this.subscribe('scene.preload.start', analyticsTimeHelper.onSceneLoadStart));
    analyticsTimeHelper.addSubscription(this.subscribe('scene.preload.end', analyticsTimeHelper.onSceneLoaded));

    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.start', analyticsTimeHelper.onMeasureToolLoadStart)
    );
    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.end', analyticsTimeHelper.onMeasureToolLoaded)
    );
    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.error', analyticsTimeHelper.onMeasureToolLoadError)
    );

    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.start', analyticsSceneDragHelper.onSceneDragStart)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.update', analyticsSceneDragHelper.onSceneDragUpdate)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.end', analyticsSceneDragHelper.onSceneDragEnd)
    );

    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.anim.start', analyticsSceneInertiaHelper.onSceneInertiaStart)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.anim.end', analyticsSceneInertiaHelper.onSceneInertiaEnd)
    );

    analyticsSceneZoomHelper.addSubscription(
      this.subscribe('scene.zoom.start', analyticsSceneZoomHelper.onSceneZoomStart)
    );
    analyticsSceneZoomHelper.addSubscription(
      this.subscribe('scene.zoom.update', analyticsSceneZoomHelper.onSceneZoomUpdate)
    );
    analyticsSceneZoomHelper.addSubscription(this.subscribe('scene.zoom.end', analyticsSceneZoomHelper.onSceneZoomEnd));
  }

  /**
   * Sets a new value for the `isVerificationNeeded` property.
   * @param isVerificationNeeded - `boolean`: new value to set.
   * @returns `void`
   */
  public setIsVerificationNeeded(isVerificationNeeded: boolean): void {
    this.isVerificationNeeded = isVerificationNeeded;
  }

  /**
   * Resets the initial scene to the default one.
   *
   * This is used by gated tour to reset the initial scene to the default one if tour is gated when the link contained a view url parameter.
   *
   * Is meant to be called before engine is started.
   * @returns `void`
   */
  public resetInitialSceneConfig(): void {
    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();

    const { tourConfig } = this.tourConfigService;

    if (tourConfig.defaultFirstScene) tourConfig.firstScene = tourConfig.defaultFirstScene;
    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();
  }

  // Helper functions

  protected setSceneTransition(inTransition: boolean): void {
    this.sceneTransition = inTransition;
    this.updateIsInTransition();
  }

  protected onGuidedViewingSceneMove(syncData: SyncData): void {
    this.yaw = syncData.yaw;
    this.pitch = syncData.pitch;
    this.zoomRatio = syncData.zoomRatio || this.zoomRatio;

    this.fov = linearScale(this.zoomRatio, [1, 0], Utils.getFovBoundary(this.getCanvasSize()));

    this.clampView();
    this.setOptimalLevel();
    this.render();
  }

  /** @todo move to controller? Why is this still triggered? */
  // because controller calls this method of a controller mixin and this is the implementation of the abstract method from controller mixin
  protected onZoomPano(zoomDelta: number, pinch = false): void {
    if (this.animatingTransition || this.isSceneMoveAnimated) return;

    this.changeZoom('fov', zoomDelta, pinch);
    this.setOptimalLevel();
    this.render();
  }

  protected onZoomFloorPlan(zoomDelta: number, pinch = false): void {
    this.changeZoom('zoom', zoomDelta, pinch);
    this.render();
  }

  // Renderer implementations

  protected onSceneMoved(): void {
    if (!this.isSceneMoveAnimated) {
      this.emit('scene.move.update', { pitch: this.pitch, yaw: this.yaw });
    }
  }

  protected preloadScenes(sceneKeys: string[]): void {
    this.scenePreloader?.preload(sceneKeys);
  }

  // private

  private loadInitialView(sceneConfig: SceneConfig) {
    if (sceneConfig.view) {
      const [pitch, yaw] = sceneConfig.view;

      // Initial view in tour config is set in degrees
      this.pitch = pitch ? Utils.toRad(pitch) : 0;
      this.yaw = yaw ? Utils.toRad(yaw) : 0;

      // Because of the weird implementation in the legacy code, we need to counter the yaw offset for the initial view
      this.yaw -= Utils.toRad(sceneConfig.camera[3]);

      // Clamp view to limited pitch and fov values
      this.clampView();
    }
  }

  private setZoomRatio(nextFov: number) {
    const fovBoundary = Utils.getFovBoundary(this.getCanvasSize());
    if (fovBoundary[0] === fovBoundary[1]) return;
    this.zoomRatio = linearScale(nextFov, fovBoundary, [1, 0]);
  }

  private changeZoom(type: 'fov' | 'zoom', byValue: number, pinch = false): void {
    let startValue = 0;
    let nextValue = 0;

    if (type === 'fov') {
      startValue = this.fov;
      nextValue = Utils.clampFov(startValue + Utils.toRad(byValue), this.getCanvasSize());
    } else {
      const increment = pinch ? byValue * 0.001 : clamp(byValue, -0.15, 0.15);
      startValue = this.zoomFloorPlan;
      nextValue = clamp(this.zoomFloorPlan + increment, 0, 1);
    }

    const zoomDirection = byValue > 0 ? 'up' : 'down';
    if (this.modeSwitchDirection !== zoomDirection) {
      this.modeSwitchDirection = zoomDirection;
      this.modeSwitchInitiatedTime = 0;
    }

    if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIMEOUT) {
      this.modeSwitchInitiatedTime = 0;
    }

    const valueDeltaIsZero = Math.abs(nextValue - startValue) < Number.EPSILON;

    // over-zooming  is happening, switch to the other mode (after a frustrating pause)
    if (valueDeltaIsZero) {
      if (zoomDirection === 'down') {
        if (this.modeSwitchInitiatedTime === 0) this.modeSwitchInitiatedTime = Date.now();
        if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIME) {
          this.emit('scene.move.zoom.min');
        }
      }
      if (zoomDirection === 'up') {
        if (this.modeSwitchInitiatedTime === 0) this.modeSwitchInitiatedTime = Date.now();
        if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIME) {
          this.emit('scene.move.zoom.max');
        }
      }

      return; // make sure not to emit events if no zooming is happening (to avoid spamming analytics)
    }

    if (type === 'fov') {
      // events only for pano zooming, floorplan zooming gets by with value change only
      const scenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: startValue };
      this.emit('scene.zoom.start', scenePos);
      this.emit('scene.move.start', scenePos);
    }

    // Pinch zoom
    if (pinch) {
      if (type === 'fov') {
        this.fov = nextValue;
        this.setZoomRatio(this.fov);
      } else {
        this.zoomFloorPlan = nextValue;
      }
      this.render();
      return;
    }

    // Wheel zoom
    this.startAnimation(
      'look',
      (progress) => {
        const currentValue = linearScale(progress, [0, 1], [startValue, nextValue]);
        if (type === 'fov') {
          this.fov = currentValue;
          this.setZoomRatio(this.fov);

          const newScenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };
          this.emit('scene.zoom.update', newScenePos);
          this.emit('scene.move.update', newScenePos);
        } else {
          this.zoomFloorPlan = currentValue;
        }
        this.render();
      },
      () => {
        this.render();
        if (type === 'fov') {
          const lastScenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };
          this.emit('scene.zoom.end', lastScenePos);
          this.emit('scene.move.end');
        }
      },
      'easeOutQuad',
      750
    );
  }

  private setTourEventSource(source: TourEventSource) {
    this.tourEventSource = source;
    if (source === 'DOMEvents') {
      this.switchController(new GestureController());
    } else {
      this.switchController(new GuestController());
    }
    this.emit('tour.eventSource.change', source);
  }

  /**
   * Watch for config service changes and create related events.
   */
  private watchConfigChanges() {
    this.tourConfigService.watch(() => {
      const theme = this.tourConfigService.theme;
      this.updateColorFilter(theme);
      this.updateHotSpotTheme(theme);
      this.render();
    });

    this.tourConfigService.onChange('watermark', (config) => {
      this.updateWatermarkConfig(config || null);
    });

    this.tourConfigService.onChange('units', (units) => {
      this.handleMeasureUnitsChange(units);
    });

    /*
     * this emits are here for backward comparability of the Engine.
     * we can delete them after all package are migrated to TourConfigService
     */
    this.tourConfigService.watch(() => {
      this.emit('branding.theme.change', this.tourConfigService.theme);
    });

    this.tourConfigService.watch(() => {
      this.emit('branding.unit.change', this.tourConfigService.units);
    });

    this.tourConfigService.watch(() => {
      this.emit('branding.showRoomArea.change', this.tourConfigService.showRoomArea);
    });
  }
}

export default Engine;
