import type { Pixel, Size, Subscription, WatermarkConfig } from '@g360/vt-types';
import { toRad, transposeM4 } from '@g360/vt-utils';

import { closeImage, fetchImage, getPerspectiveMatrix } from '../../common/Utils';
import {
  createTexture,
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import fragmentShaderSource from './cube.fs.glsl';
import vertexShaderSource from './cube.vs.glsl';
import { createWatermarkPlane, rotateWatermarkPlane } from './watermarkUtils';

type WatermarkProgramEvents = 'render' | 'error.set' | 'error.clear';

class WatermarkProgram {
  name: ProgramName;
  orderIndex = 0;

  yaw = 0;
  pitch = 0;
  fov = toRad(120);

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;

  private vertexBuffer: WebGLBuffer | null;
  private textureBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private textureAttribute = 0;
  private perspectiveUniform: WebGLUniformLocation | null = null;
  private alphaUniform: WebGLUniformLocation | null = null;
  private alphaFixUniform: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private webglReady = false;
  private alpha = 1.0;
  private yawOffset = 0;
  private rotationMatrix: number[] = [];
  private perspectiveMatrix: number[] = [];
  private rotatedPerspective: number[] = [];
  private textureCoords = new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]);
  private textureObject: WebGLTexture | null;
  private watermarkReloaded = false;
  private watermarkTextureReady = false;

  private abortController = window.AbortController ? new AbortController() : null;

  private watermarkConfig: Required<WatermarkConfig> | null = null;
  /** Watermark image aspect ratio */
  private aspectRatio: number | null = null;
  /** List of vertices for each watermark location */
  private watermarkNodes: Float32Array[] | null = null;

  private listeners: { [key: string]: ((payload) => void)[] } = {};

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'WatermarkProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();
    this.textureObject = createTexture(this.gl);
  }

  public init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.perspectiveUniform = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.alphaFixUniform = this.gl.getUniformLocation(this.program, 'u_alpha_fix');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
    }

    this.webglReady = true;
  }

  /** Remove the watermark but keep the program - abort image fetch, remove texture from gpu and clear nodes array */
  public removeWatermark(): void {
    this.abortController?.abort();
    this.watermarkNodes = null;
    this.gl.deleteTexture(this.textureObject);
    this.emit('render');
  }

  /** Remove the watermark and destroy the webgl program and event emitter */
  public destroy(): void {
    this.webglReady = false;

    this.removeWatermark();
    this.destroyEventEmitter();
    destroyProgram(this.gl, this.program);
  }

  async loadWatermark(watermarkConfig: WatermarkConfig): Promise<boolean> {
    // If it is the same image, just update the config and nodes
    if (this.watermarkTextureReady && watermarkConfig.image === this.watermarkConfig?.image) {
      this.watermarkConfig = {
        count: 6,
        scale: 0.5,
        yPosition: 0.3,
        ...watermarkConfig,
      };

      this.createWatermarkNodes();
      return true;
    }

    this.watermarkConfig = {
      count: 6,
      scale: 0.5,
      yPosition: 0.3,
      ...watermarkConfig,
    };

    this.watermarkTextureReady = false;
    const watermarkImage = await fetchImage(watermarkConfig.image, this.abortController);

    if (watermarkImage) {
      const textureObject = createTexture(this.gl);
      setTextureFromImage(this.gl, textureObject, watermarkImage, { useAlphaChannel: true });

      this.gl.deleteTexture(this.textureObject);
      this.textureObject = textureObject;

      this.aspectRatio = watermarkImage.height / watermarkImage.width;

      this.createWatermarkNodes();
      closeImage(watermarkImage);
      this.emit('error.clear', 'WATERMARK_INTERRUPTED');
      this.watermarkTextureReady = true;
      return true;
    }

    // Try to reload watermark one time, could improve the issue in case if CORS errors happen on the first request
    // Also reload only if fetch is not aborted (watermarkImage === undefined if aborted)
    if (watermarkImage === null && !this.watermarkReloaded) {
      this.watermarkReloaded = true;

      setTimeout(() => {
        this.loadWatermark(watermarkConfig);
      }, 2000);
    }

    this.emit('error.set', 'WATERMARK_INTERRUPTED');
    return false;
  }

  /** Creates new set of watermarks nodes which then are used to render the watermark texture, this also triggers
   * the global render function if emitter is properly attached
   */
  createWatermarkNodes(): void {
    if (!this.watermarkConfig || !this.aspectRatio) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn('Trying to create geometry with missing watermark config or image size');
      }

      return;
    }

    this.watermarkNodes = [];

    const watermarkPlane = createWatermarkPlane(this.watermarkConfig, this.aspectRatio);
    const { count } = this.watermarkConfig;

    for (let i = 0; i < count; i += 1) {
      const watermarkAngle = (360 / count) * i;
      const rotatedPlane = rotateWatermarkPlane(watermarkAngle, watermarkPlane);

      // Vertices for webgl are flattened and stored in a Float32Array
      this.watermarkNodes.push(new Float32Array(rotatedPlane.flat()));
    }

    this.emit('render');
  }

  drawWatermark(geometry: Float32Array): void {
    // Set texture coordinates
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.alphaUniform, this.alpha);
    this.gl.uniform1i(this.alphaFixUniform, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, geometry, this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
  }

  subscribe(event: WatermarkProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  unsubscribe(event: WatermarkProgramEvents, fn: (payload) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  render(): void {
    if (!this.renderer.isSceneLayoutReady || !this.watermarkNodes || !this.webglReady) return;

    this.draw();
  }

  private draw(): void {
    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.activeTexture(this.gl.TEXTURE0);

    const size: Size<Pixel> = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

    this.rotatedPerspective = getPerspectiveMatrix(this.fov, size, 0.1, 100.0, -this.pitch, this.yaw);

    this.gl.uniformMatrix4fv(this.perspectiveUniform, false, new Float32Array(transposeM4(this.rotatedPerspective)));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.watermarkNodes!.forEach((nodeGeometry) => this.drawWatermark(nodeGeometry));

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  /** Destroy emitter by removing all subscribers */
  private destroyEventEmitter(): void {
    this.listeners = {};
  }

  private emit(event: WatermarkProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }
}

export default WatermarkProgram;
